import AdminVue from '@/views/Admin.vue'
import LoginVue from '@/views/Login.vue'
import OverviewVue from '@/views/Overview.vue'
import NotFound from '@/views/NotFound.vue'
import EditprofileVue from '@/views/Editprofile.vue'
import db_community from '@/db/controller/community'
import auth from '@/db/controller/auth'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:b2b_uid/overview",
      name: "Overview",
      component: OverviewVue
    },

    {
      path: "/:b2b_uid/admin",
      name: "Admin",
      component: AdminVue,
    },
    {
      path: "/:b2b_uid/login",
      name: "Login",
      component: LoginVue
    },
    {
      path: "/:b2b_uid/editprofile/:uid",
      name: "EditProfileWithID",
      component: EditprofileVue
    },
    {
      path: "/:b2b_uid/editprofile",
      name: "EditProfile",
      component: EditprofileVue
    },
    {
      path: "/:b2b_uid",
      redirect: { name: "Overview" }
    },
    {
      path: '/404',
      name: 'not-found',
      component: NotFound
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'not-found' }
    }
  ]
})

let validTenantIds: any = null;

router.beforeEach(async (to, from) => {

  await auth.signInWithToken()

  store.state.user = await auth.userState(to.params?.b2b_uid as string)
  console.log(store.state.user)
  if (store.state.user.uid == 'i4AH0ci2IfYZ61FcCIWe2kZA3mh2') {
    store.state.environment.isConfig = true
  }
  if (to.name == 'Admin' && store.state.environment.isConfig == false) return { name: 'Overview', params: { b2b_uid: to.params.b2b_uid } }

  if (to.params?.b2b_uid && to.params.b2b_uid) {
    if (validTenantIds == null) {
      validTenantIds = await db_community.getTenantIDs()
    }
    if (!validTenantIds.includes(to.params.b2b_uid)) {
      return { name: 'not-found' }
    }
  }
});

export default router
