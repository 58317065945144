<template>
    <div>
<svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    
    <title>calendar [#1197]</title>
    <desc>Created with Sketch.</desc>
    <defs>

</defs>
    <g id="Page-1" stroke="none" stroke-width="1">
        <g id="Dribbble-Light-Preview" transform="translate(-260.000000, -2799.000000)">
            <g id="icons" transform="translate(56.000000, 160.000000)">
                <path d="M215,2650 L215,2653 C215,2653.552 214.552,2654 214,2654 C213.448,2654 213,2653.552 213,2653 L213,2651 C213,2650.448 212.552,2650 212,2650 C211.448,2650 211,2649.552 211,2649 C211,2648.448 211.448,2648 212,2648 L213,2648 C214.105,2648 215,2648.895 215,2650 L215,2650 Z M222,2656 C222,2656.552 221.552,2657 221,2657 L207,2657 C206.448,2657 206,2656.552 206,2656 L206,2646 C206,2645.448 206.448,2645 207,2645 L221,2645 C221.552,2645 222,2645.448 222,2646 L222,2656 Z M222,2641 L222,2640 C222,2639.448 221.552,2639 221,2639 C220.448,2639 220,2639.448 220,2640 L220,2641 L215,2641 L215,2640 C215,2639.448 214.552,2639 214,2639 C213.448,2639 213,2639.448 213,2640 L213,2641 L208,2641 L208,2640 C208,2639.448 207.552,2639 207,2639 C206.448,2639 206,2639.448 206,2640 L206,2641 C204.895,2641 204,2641.895 204,2643 L204,2657 C204,2658.104 204.895,2659 206,2659 L222,2659 C223.105,2659 224,2658.104 224,2657 L224,2643 C224,2641.895 223.105,2641 222,2641 L222,2641 Z" id="calendar-[#1197]">

</path>
            </g>
        </g>
    </g>
</svg>
</div>
   </template>
   <script>
   export default {
   props: ["color1", "color2", "background"],
   }
</script>
