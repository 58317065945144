export default [
  {
    name: "Test data",
    function: "Should not be visible",
    email: "jeff@gmail.com",
    phone: "+31 6 12312312",
    uid: "123uid",
    socials: null,
      company: {
        area: null,
        name: null
      },
      profileImage:null
    
      
  }
]