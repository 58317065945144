<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style="enable-background: new 0 0 512 512"
      xml:space="preserve"
    >

      <path
        class="st0"
        d="M315.9,413.5c0,5.2-1.9,10-5.2,13.6H133.9c-11.7-12.6-2-34.6,15.4-34.2h146
	C306.7,392.9,315.9,402.1,315.9,413.5z M133.9,441c-11.7,12.6-2,34.6,15.4,34.2h146c17.4,0.4,27.1-21.7,15.3-34.2H133.9z M192,511
	h60.7c13,0,23.7-9.5,25.8-21.9H166.2C168.3,501.5,179,511,192,511z M323.3,306.2c-21.4,18.6-34.5,44.8-37.2,72.8h-44.3V99
	c0-5.9,5-10.6,10.9-10.1C362.1,98.6,404.8,235.9,323.3,306.2L323.3,306.2z M342.7,212.6c0.3-42-27.3-81.4-67.1-95.1
	c-4.1-1.5-8.8,0.7-10.2,4.9c-1.5,4.2,0.6,8.7,4.8,10.2c33.4,11.5,56.7,44.6,56.4,80C326.8,223.1,342.6,223.1,342.7,212.6
	L342.7,212.6z M139.2,331.4c5.2-5.4,17-12.4,21.4-2.5c1.6,4.1-0.5,8.8-4.7,10.4c-18,8.2-8.7,32.5,2.5,39.7h35.9
	c7.7-5.6,12.7-14.6,12.7-24.8V127.5c-0.2-32.7-45-42.3-58.4-12.6c10.8,2.1,42.7,29.3,19.8,34.4c-7.6-0.3-9.3-10.4-15.3-13.8
	c-30.1-23.8-72.9,8.8-59.9,43.5c13.6-2.5,31.5,4.2,40.6,15.7c4.5,4.9,0.4,13.4-6.1,13.2c-2.3,0-4.5-1-6.1-2.9
	c-2.1-2.5-6.8-7.1-14.2-9.2c-5.2-1.7-12.4-1.3-16.6,0.1c-41.5,12.1-44.9,71.1-5.3,88c5.4-8.7,37-32.8,42.7-15.9
	c1.1,4.3-1.6,8.7-5.8,9.7c-45.6,11.3-39.3,75.3,7.7,76.6C130.2,346.5,132.7,337.9,139.2,331.4L139.2,331.4z M349.5,114.1
	c2.1,0,4.1-0.8,5.7-2.3l37.1-37.1c7.3-7.6-3.8-18.7-11.3-11.3l-37.1,37.1C338.7,105.2,342.6,114.3,349.5,114.1L349.5,114.1z
	 M446.5,205.7h-52.4c-10.6,0.2-10.5,15.9,0,16h52.4C457.1,221.6,457,205.9,446.5,205.7z M355.1,315.8c-7.6-7.3-18.7,3.8-11.3,11.3
	l37.1,37.1c7.5,7.4,18.7-3.9,11.3-11.3C392.2,352.8,355.1,315.8,355.1,315.8z M241.8,69.5c4.4,0,8-3.6,8-8V9c0-4.4-3.6-8-8-8
	s-8,3.6-8,8v52.4C233.8,65.9,237.4,69.5,241.8,69.5z"
      />
    </svg>
  </div>
</template>
   <script>
export default {
  props: ['color1', 'color2', 'background']
}
</script>