<template>
  <div class="icon-row">
    <SvgIcon :IconName="IconName" />

    <template v-if="link==null">
      
      <p class="row-text">{{ contents }}</p>
      </template>
      <template v-else>
        <a :href="link" class="row-text">{{ contents }}</a>
      </template>

  </div>
</template>


<script>
import store from '@/store'
import SvgIcon from '@/components/general/SvgIcon.vue'
export default {
  props: ['IconName', 'contents'],
  components: {
    SvgIcon
  },

  data() {
    return {
      store: store,
      link: null
    }
  },
  created() {
    if (this.IconName=="LinkedIn") {
      this.link = this.contents
    } else if (this.IconName == "Phone")  {
      this.link = "tel:"+ this.contents
    } else if (this.IconName == "Email") {
      this.link = "mailto:"+ this.contents
    } else if (this.IconName == "Instagram") {
      this.link = this.contents
    } else if (this.IconName == "Email") {
      this.link = "mailto:" + this.contents
    }
  }

}
</script>

<style scoped>
.icon-row {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 5px;
}

.row-text {

}

img {
  width: 22px;
  /* filter: drop-shadow(0px 10000px 0 v-bind('store.state.tenant.colors.primaryColor')); */
  /* transform: translateY(-10000px); */
  /* object-fit: contain; */
  margin: 4px;
}

p {
  color: white;
}

a {
  color: white;
}
</style>

