<template>
  <div class="content">
    <div class="center">
      <img :src="profile.profileImage" class="profileImage" />
    </div>
    <h1>{{ header }}</h1>
    <p>{{ paragraph }}</p>
    <p>{{ paragraph2 }}</p>
    <div class="combined">
      <button class="cancel" @click="closeContent()">cancel</button>
      <button class="continue" @click="deleteUser()">Continue</button>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: ['profile'],
  data() {
    return {
      header: 'Are you sure?',
      // TODO: rename
      paragraph: 'Deze wijziging kan niet worden teruggedraaid.',
      // paragraph2: 'Klik op continue om de member te verwijderen',
      //   logo: store.state?.tenant?.logo ?? "../src/assets/images/mozo_logo_nopadding-01.svg",
      store: store
    }
  },
  computed: {
    paragraph2() {
      let bob = 'Klik op continue om deze gebruiker te verwijderen'
      return bob
    }
  },
  methods: {
    // TODO: add method for buttons
    closeContent() {
      console.log('cancel clicked')
      this.$emit('close')
    },
    deleteUser() {
      console.log('continue clicked')
      // this.$router.push({ name: 'Login'})
      this.$emit('deleteUser')
    }
  }
}
</script>



<style lang="scss" scoped>
.content {
  border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center {
  display: flex;
  justify-content: center;
}

.profileImage {
  width: 100%;
  max-height: 200px;
  border-radius: 10px;
  object-fit: contain;
  border-radius: 28px;
}

h1 {
  color: v-bind('store.state.tenant.colors.textColor');
  font-size: 20px;
  text-align: center;
}

p {
  color: v-bind('store.state.tenant.colors.textColor');
  margin-top: 20px;
}

.cancel {
  background: v-bind('store.state.tenant.colors.backgroundColor');
  color: v-bind('store.state.tenant.colors.primaryColor');
  border: 0px;
  border-radius: 10px;
  display: block;
}

.continue {
  background: v-bind('store.state.tenant.colors.primaryColor');
  color: v-bind('store.state.tenant.colors.backgroundColor');
  border: 0px;
  border-radius: 10px;
  display: block;
}

.combined {
  border: 0px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
}
</style>