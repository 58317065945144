<template>
  <div>
    <div class="overview" style="position: relative" v-if="isLoaded">
      <div class="outer-border expanded">
        <Header :back="false" :backToHome="true" />
        <div class="top-bar">
          <input class="search-bar" placeholder="bijv. company/role/branche/name" ref="searchQuery" @input="search()" />
        </div>
        <main>
          <template v-for="(person, i) in filteredMembers" :key="i">
            <div v-if="person.status == 'completed'" class="profile-card" @click="setProfile(person)">
              <div style="position: relative">
                <img :src="person.profileImage ?? store.state.tenant.logo" class="profile-image" />
                <div v-if="person.company.area" :style="'font-size:13px;font-weight: 800;opacity:70%;padding: 6px 15px;border-radius:28px;bottom: 5%;right: 5%;position:absolute; color:' +
      store.state.tenant.colors.backgroundColor +
      ';background-color:' +
      store.state.tenant.colors.primaryColor
      ">
                  {{ person.company.area }}
                </div>
              </div>
              <div class="details">
                <div class="split-column">
                  <h1 style="font-size: 14px; text-align: center; font-weight: 700;">
                    {{
      truncatedText(person.firstname + ' ' + person.prefix + ' ' + person.lastname, 30)
    }}
                  </h1>
                  <div v-if="person.company != null">
                    <h2 style="text-align: center;height; 19.2px" v-if="person.company.name != null">
                      {{ truncatedText(person.company.name) }}
                    </h2>
                    <h3 style="text-align: center; height; 19.2px;" v-if="person.company.role != null">
                      #{{ truncatedText(person.company.role) }}
                    </h3>
                  </div>
                </div>
                <div class="socials-list">
                  <div v-for="social in socials" :key="social.name">
                    <a v-if="person.socials && social.name in person.socials"
                      :href="getSocialUrl(person.socials[social.name], social.name)" @click.stop target="_blank">
                      <SvgIcon :IconName="social.name" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-if="isSearching == false" class="page-selector">
            <div>
              <template v-for="(page, i) in displayedPages" :key="i">
                <!-- Display page button with different styling if it's the current page -->
                <button @click="pageSelect(page, true)"
                  :class="{ 'current-page': page === parseInt($route.query.p ?? '0') + 1 }">
                  {{ page }}
                </button>
                <!-- Display dots if the next page and the last page are not consecutive -->
                <span v-if="i === displayedPages.length - 2 && page + 1 < displayedPages[i + 1]">...</span>
              </template>
            </div>
          </div>
        </main>
      </div>

      <div v-if="clickedProfile != null">
        <Modal @close="clickedProfile = null">
          <Profile :profile="clickedProfile" @close="clickedProfile = null" />
        </Modal>
      </div>

      <div v-if="redirect">
        <Modal @close="closeRedirect()">
          <RedirectContent @close="closeRedirect()" />
        </Modal>
      </div>

      <div v-if="showPopup" class="fastpopup">
        <div class="profile">
          <div class="body">
            <section class="normal-padding">
              <div v-if="store.state.userProfile.profileImage" class="center" style="margin-bottom: 5%">
                <div class="backgroundImage"
                  :style="`background-image: url(${store.state.userProfile.profileImage}); background-size: cover; background-position: center; background-repeat: no-repeat;`">
                </div>

                <h1 v-if="store.state.userProfile.firstname" style="font-weight: 800; color: white">
                  Hi, {{ store.state.userProfile.firstname }}
                </h1>
                <p style="font-weight: 800">
                  We would love to know more about you and so would our community.
                </p>

                <p style="font-weight: 800">
                  Click 'continue' and complete your profile immediately or
                </p>

                <button class="button-style" @click="clickThrough()">CONTINUE</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/general/Modal.vue'
import Profile from '@/views/Profile.vue'
import RedirectContent from '@/components/profile/RedirectContent.vue'
import Header from '@/components/general/Header.vue'
import db_community from '@/db/controller/community'
import store from '@/store'
import auth from '@/db/controller/auth'
import { ref, watch } from 'vue'
import router from '@/router'
import SvgIcon from '@/components/general/SvgIcon.vue'

let b2b_uid = null

export default {
  data() {
    return {
      clickedProfile: null,
      redirectClose: false,
      socials: [
        { img: '../src/assets/socials/LinkedIn.svg', link: null, name: 'LinkedIn' },
        { img: '../src/assets/socials/Email.svg', link: null, name: 'Email' },
        { img: '../src/assets/socials/Phone.svg', link: null, name: 'Phone' }
      ],
      filteredMembers: ref(store.state.members),
      store: store,
      showPopup: false,
      isLoaded: false,
      accountPerPage: 16,
      isSearching: false
    }
  },

  components: {
    Modal,
    Profile,
    Header,
    RedirectContent,
    SvgIcon
  },
  async created() {
    store.state.b2b = {
      backlink: 'https://themozo.app/' + store.state.tenant.tenant_name
    }

    this.filteredMembers = await this.preferMozo('x9Pj17dZjseBTFhHFaa9MLwCQaI2', 2)

    let query = this.$route.query
    let index = parseInt((query.p ?? '0').toString())

    this.filteredMembers = this.filteredMembers.slice(
      index * this.accountPerPage,
      (index + 1) * this.accountPerPage
    )

    if (
      store.state.userProfile.status == 'completed' &&
      (store.state.userProfile.isFirstTime == null || store.state.userProfile.isFirstTime == true)
    ) {
      store.state.userProfile.isFirstTime = false
      this.showPopup = true
    } else {
      this.showPopup = false
    }

    this.isLoaded = true
  },
  methods: {
    truncatedText(txt, max) {
      if (max == null) {
        max = 22
      }
      return txt.length > max ? txt.slice(0, max) + '...' : txt
    },

    async clickThrough() {
      store.state.userProfile.isFirstTime = false
      await db_community.updateProfile(
        this.$route.params.b2b_uid,
        store.state.userProfile.uid,
        store.state.userProfile
      )

      this.$router.push({ name: 'EditProfile' })
    },

    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds))
    },
    preferMozo(uid, position) {
      const members = [...this.filteredMembers] // Clone the array to avoid direct mutations
      const index = members.findIndex((member) => member.uid === uid)

      let obj
      if (index !== -1) {
        obj = members[index]
        members.splice(index, 1)
      }

      if (obj) {
        members.splice(position, 0, obj) // Insert the member object, not the position
      }

      return members
    },

    getSocialUrl(url, social_name) {
      if (social_name.toLowerCase() == 'email') {
        return 'mailto:' + url
      } else if (social_name.toLowerCase() == 'phone') {
        return 'tel:' + url
      } else {
        return url
      }
    },
    async search() {
      const val = this.$refs.searchQuery.value;

      const fields = [
        'firstname',
        'prefix',
        'lastname',
        'company-name',
        'company-area',
        'company-role',
        'tag',
        'socials-Email'
      ];

      const searchTerms = val.toLowerCase().split(' '); // Split the input value into individual search terms
      const uniqueMembers = new Map();

      if (val.length > 0) {
        store.state.members.forEach((member) => {
          const isMemberIncluded = searchTerms.every((term) => {
            return fields.some((field) => {
              if (field.includes('-')) {
                const [parent, child] = field.split('-');
                return member[parent]?.[child]?.toLowerCase().includes(term);
              }
              return member[field]?.toLowerCase().includes(term);
            });
          });
          // Use a unique identifier for each member to prevent duplicates
          if (isMemberIncluded) {
            uniqueMembers.set(member.uid, member);
          }
        });

        // Convert the Map values to an array
        this.filteredMembers = Array.from(uniqueMembers.values());

        // Optionally limit the number of results
        // this.filteredMembers = this.filteredMembers.slice(-100); // Consider if you really want to slice the last 100 items only
        this.isSearching = true;
      } else {
        await this.pageSelect(1, false);
        this.isSearching = false;
      }
    },


    getHistoryLength() {
      return window.history.length
    },
    setProfile(person) {
      this.clickedProfile = person
    },
    closeRedirect() {
      this.redirectClose = true
    },
    pageAmount() {
      return Math.floor(store.state.members.length / this.accountPerPage) + 1
    },
    async pageSelect(index, setPage) {
      this.isLoaded = false

      if (setPage) {
        this.$router.replace({ name: 'Overview', query: { p: index - 1 } })
      }

      this.filteredMembers = store.state.members

      this.filteredMembers = await this.preferMozo('x9Pj17dZjseBTFhHFaa9MLwCQaI2', 3)

      this.filteredMembers = this.filteredMembers.slice(
        (index - 1) * this.accountPerPage,
        index * this.accountPerPage
      )
      window.scrollTo(0, 0);
      this.isLoaded = true

    }
  },

  computed: {
    /**
     * Checks whether the user has filled in profile information, and if not, gets prompted to do so
     * (if the user has filled in their information, the status will no longer be 'invited' but 'completed')
     */
    redirect() {
      if (store.state.userProfile?.status === 'invited' && this.redirectClose === false) {
        return true
      }
      return false
    },

    displayedPages() {
      let query = this.$route.query
      let index = parseInt((query.p ?? '0').toString()) + 1 // +1 to make it 1-based
      let pages = []
      const total = this.pageAmount() - 1

      // If the current page is not defined or invalid, default to 1
      if (isNaN(index) || index < 1) {
        index = 1
      }

      // Calculate the halfway point
      const halfway = Math.ceil(total / 2)

      // If the current page is in the first half

      if (index == 1) {
        pages.push(1, 2, 3, total)
      } else if (index == total) {
        pages.push(1)
        pages.push(index - 2)
        pages.push(index - 1)
        pages.push(index)
      } else if (index <= halfway) {
        if (index !== 1) pages.push(index - 1) // Previous page, but don't add if current page is the first page
        pages.push(index) // Current page
        if (index !== total) pages.push(index + 1) // Next page, but don't add if current page is the last page
        if (total !== 1 && total !== index + 1) pages.push(total) // Last page
      }
      // If the current page is in the second half
      else {
        if (index !== 1) pages.push(1) // First page, but don't add if current page is the first page
        pages.push(index - 1) // Previous page
        pages.push(index) // Current page
        if (index !== total) pages.push(index + 1) // Next page
      }

      return pages
    }
  },
  async beforeRouteEnter(to, from, next) {
    // store.state.tenant.colors = ["#000","#000",{icons:["#000","#000","#000"]}]

    await db_community.loadDataFromDBInStateManager(to.params.b2b_uid + '', true)
    next()
  }
}
</script>



<style lang="scss">
.overview {
  .SvgIcons .svg-img {
    width: 24px;
    height: 24px;
  }

  .profile-card {
    min-width: 100px;
    width: calc(50% - 16px);
    max-width: 200px;
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    display: inline;
    margin: 8px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
      width: calc(33% - 45 * 0.33333px);
    }

    @media screen and (max-width: 500px) {
      width: calc(50% - 16px);
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      max-width: none;
    }

    .profile-image {
      width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 10px;
    }

    .details {
      padding: 0 10px 10px 10px;
      display: flex;
      flex-direction: column;
    }

    h1 {
      color: v-bind('store.state.tenant.colors.textColor');
      font-size: 20px;
    }

    h2 {
      color: v-bind('store.state.tenant.colors.textColor');
      font-size: 12px;
    }

    h3 {
      font-size: 12px;
    }

    .socials-list {
      display: flex;
      justify-content: center;

      div {
        display: flex;
        flex-direction: column;
        justify-content: end;
        // height: 30px;
      }
    }

    .socials-img {
      width: 22px;
      filter: drop-shadow(0px 10000px 0 v-bind('store.state.tenant.colors.primaryColor'));
      transform: translateY(-10000px);
      // background-color: blue;
      object-fit: contain;
      margin: 4px;
    }
  }

  .top-bar {
    display: flex;
    justify-content: end;
  }

  .outer-border {
    padding: 10px 10px 0 10px;
    overflow-y: auto;
    height: 100%;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
  }

  .expanded {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }

  .arrow-back {
    display: inline;
    width: 40px;
    height: 40px;
    margin-right: 20px;
  }

  p {
    color: v-bind('store.state.tenant.colors.textColor');
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: center;
    height: 120px;
  }

  input {
    /* TODO: add dynamic colors from firestore */
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 5px;
    height: 30px;
    margin-right: 8px;
  }

  main {
    // height: calc(100% - 40px);
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: v-bind('store.state.tenant.colors.primaryColor');
  }

  .search-bar {

    @media screen,
    (max-width: 800px) {
      width: 200px;
    }

    outline: none;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 5px;
    margin: 0 2%;
    box-shadow: -1px -1px 2px 1px v-bindv-bind('store.state.tenant.colors.primaryColor'),
    0px 0px 0px 1px v-bind('store.state.tenant.colors.primaryColor');
    // height: 30px;
    padding: 5px 20px;
    height: 40px;
  }

  .fastpopup {
    background-color: black;
    width: 80%;
    height: 80%;
    max-height: 500px;

    position: absolute;
    top: 5%;

    left: 0;
    right: 0;

    margin: auto;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10%;
  }

  .button-style {
    margin-top: 5%;
    padding: 8px 30px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    border-radius: 28px;
    background-color: v-bind('store.state.tenant.colors.primaryColor') !important;
    color: black;
  }

  .backgroundImage {
    width: 200px;
    height: 200px;
    border-radius: 28px;
    background-size: cover; // this will cover the entire div with the image
    background-position: center; // this will center the image
    background-repeat: no-repeat; // this will prevent the image from repeating
  }

  button {
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 5px;
    margin: 0 4px;
    box-shadow: -1px -1px 2px 1px v-bind('store.state.tenant.colors.secondaryColor'),
      0px 0px 0px 1px v-bind('store.state.tenant.colors.secondaryColor');
    padding: 5px 30px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  button.current-page {
    border-color: red;
    color: red;
  }

  .page-selector {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>