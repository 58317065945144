import { auth } from "@/db/firebase/index"
import { signInWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithCustomToken, sendPasswordResetEmail, deleteUser } from "firebase/auth";

import xxx from "@/db/controller/community"

import axios from "axios";

export default {
    async userState(b2b_uid: string, paramAuth: any = auth) {
        return new Promise((resolve, reject) => {
            const unsubscribe = paramAuth.onAuthStateChanged((user: any) => {
                xxx.setLastLogin(user, new Date, b2b_uid)
                unsubscribe();
                resolve(user);
            }, reject);
        });
    },
    async signIn(email: string, password: string, paramAuth: any = auth) {

        return await signInWithEmailAndPassword(paramAuth, email, password)
            .then((x) => {

                return ""
            })
            .catch((err: any) => {
                console.log(err)
                let errorCode = err.code

                if (errorCode === "auth/invalid-email") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/user-disabled") {
                    return "User account has been disabled"
                } else if (errorCode == "auth/too-many-requests") {
                    return "Sorry you have too many login attempts try it again in 10 minutes."
                } else if (errorCode === "auth/user-not-found") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/wrong-password") {
                    return "Sorry you have used the wrong username or password"
                } else {
                    return "Sorry you have used the wrong username or password"
                }
            })
    },

    async getParameterByName(parameter: string) {
        var url = new URL(window.location.href);
        return url.searchParams.get(parameter);
    },

    async signInWithToken(paramAuth: any = auth) {
        let token: any = null


        // Get session_id from the URL's token query parameter
        const sessionIdFromURL = await this.getParameterByName('token')
        if (sessionIdFromURL == null) return null

        if (!sessionIdFromURL) {
            console.error('No session ID provided in the URL.')
            return
        }

        let data = {
            session_id: sessionIdFromURL
        }

        let config = {
            method: 'post',
            url: 'https://apiv2.themozo.app/mini/get-session',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        try {
            const response = await axios.request(config)
            token = response.data.token

        } catch (error) {
            console.log(error)
        }

        const url = new URL(window.location.toString());
        url.searchParams.delete('token');  // Remove token parameter
        window.history.replaceState({}, document.title, url.toString());

        if (token == null) return null
        console.log("Token: ", token)
        const user = await signInWithCustomToken(paramAuth, token)
            .then((userCredential) => {
                return "";  // or perhaps return userCredential.user
            })
            .catch((err: any) => {
                console.log("STOP!!", err);
                let errorCode = err.code;

                if (errorCode === "auth/invalid-custom-token") {
                    return "Invalid token provided";
                } else if (errorCode === "auth/custom-token-mismatch") {
                    return "Token mismatch or token is not for this app";
                } else {
                    return "Error during authentication. Please try again later.";
                }
            });


    },
    async logout(paramAuth = auth) {
        await paramAuth
            .signOut()
            .then(async () => {
                console.log("Successfully logged out")
                return null;
            })
            .catch(() => {
                throw "Not succesfully logout!";
            });
    },

    async emailExists(email: string) {

        return await fetchSignInMethodsForEmail(auth, email)
            .then(function (signInMethods) {
                if (signInMethods.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }).catch(function (err) {
                return false;
            });


    },

    async passwordReset(email: string) {

        try {
            await sendPasswordResetEmail(auth, email)
            // if response is successful, return true
            console.log("Password reset email sent successfully")
            return true
        } catch (error) {
            return false
        }
    },




}