<template>
  <div class="admin">
    <div class="expanded">
      <div class="outer-div">
        <Header :back="'Overview'" />
        <div class="align-right">
          <button @click="inviteUser = true">Add new user</button>
          <download-excel :data="store.state.members" name="Members" :fields="{
            name: 'name',
            email: 'socials.Email',
            phone: 'socials.Phone',
            'work function': 'company.role',
            uid: 'uid'
          }">
            <button class="download-button">
              <div style="padding-right: 15px">
                <SvgIcon :IconName="'Excel'" />
              </div>
              Export to excel
            </button>
          </download-excel>
          <button @click="checkOpeningHours()">Change opening hours</button>
          <button @click="showInvitedUsers()">Show Invited Users</button>


          <input class="search-bar" placeholder="bijv. company/role/branch/member" ref="searchQuery" @change="search()"
            @input="search()" @keydown="search()" />
        </div>
        <main>
          <div id="membersTable">
            <div class="overview-row">
              <p class="heading-8">Name</p>
              <p class="heading-8">Work Function</p>
              <p class="heading-8">Email</p>
              <p class="heading-8">Phone</p>
              <p class="heading-8">
                Last Visited
                <!-- <span v-if="sortByLastVisited === 'asc'">&#9650;</span>
                <span v-if="sortByLastVisited === 'desc'">&#9660;</span> -->
              </p>
              <p class="heading-8" @click="toggleSortByLastLogin()">
                Last Login
                <span v-if="sortByLastLogin === 'asc'">&#9650;</span>
                <span v-if="sortByLastLogin === 'desc'">&#9660;</span>
              </p>
            </div>
            <div v-for="person in filteredMembers" :key="person.id" :style="getTableStyle(person)" :id="person.uid"
              class="overview-row" @click="updatePerson()">
              <p>
                {{
                  person.firstname != null
                    ? person.firstname + ' ' + person.prefix + ' ' + person.lastname
                    : ''
                }}
              </p>
              <p>{{ person.company?.role }}</p>
              <p>{{ person.socials?.Email }}</p>
              <p>{{ person.socials?.Phone }}</p>
              <p>{{ person.lastVisited ? new Date(person.lastVisited.toDate()).toLocaleDateString('en-Us', {
                day:
                  '2-digit', month: 'long', year: 'numeric'
              }) : 'Never' }}</p>
              <p> {{ person.lastLogin ? new Date(person.lastLogin.toDate()).toLocaleDateString('en-Us', {
                day:
                  '2-digit', month: 'long', year: 'numeric'
              }) : 'Never' }}</p>
              <div class="buttons">
                <div v-if="person.status == 'invited'">
                  <button @click.stop="reInvite(person.socials.Email)">
                    <SvgIcon :IconName="'Refresh'" />
                  </button>
                </div>
                <div v-else>
                  <button @click.stop="reroute('EditProfileWithID', person.uid)">
                    <SvgIcon :IconName="'Edit'" />
                  </button>

                </div>
                <div v-if="person.status == 'completed'">
                  <button @click.stop="resetPassword(person.socials.Email)">
                    <SvgIcon :IconName="'resetPassword'" />
                  </button>
                </div>
                <button @click.stop="conformation(person)">
                  <SvgIcon :IconName="'Trash'" />
                </button>
                <!-- button for password reset for this user only visible for users that have status completed -->


              </div>
              <!-- if person is invited, show a absolute lable saying invited -->
              <div v-if="person.status == 'invited'" class="invited-label">
                <p>Invited</p>
              </div>
            </div>
          </div>
          <div class="page-selector">
            <div>
              <button v-for="(index, i) in pageAmount()" @click="pageSelect(index)" :key="i">
                {{ index }}
              </button>
            </div>
          </div>
        </main>
      </div>

      <div v-if="clickedProfile != null && confirmDelete === false">
        <Modal @close="clickedProfile = null">
          <Profile :profile="clickedProfile" />
        </Modal>
      </div>

      <OpeningHoursOverlay v-if="showOpeningHoursOverlay" :openingHours="openingHoursData"
        @close="showOpeningHoursOverlay = false" @update-hours="handleUpdateOpeningHours" />

      <div v-if="inviteUser">
        <Modal @close="inviteUser = false">
          <div class="background">
            <form @submit.prevent="invite()" class="inviteForm">
              <input v-model="inviteUserEmail" type="email" placeholder="Email" required />
              <input style="cursor: pointer" class="invite-button" type="submit" value="Invite" />
            </form>
          </div>
        </Modal>
      </div>

      <div v-if="confirmDelete">
        <Modal :style="'max-width:400px;margin:auto;margin-top:50px;padding:20px;'" @close="closeConformation()">
          <ConformationContent @deleteUser="deleteUser(clickedProfile)" @close="closeConformation()"
            :profile="clickedProfile" />
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/components/general/Modal.vue'
import Profile from '@/views/Profile.vue'
import OpeningHoursOverlay from '@/components/admin-vue/OpeningHoursOverlay.vue';
import Header from '@/components/general/Header.vue'
import db_community from '@/db/controller/community'
import store from '@/store'
import auth from '@/db/controller/auth'
import { computed, ref, watch } from 'vue'
import router from '@/router'
import ConformationContent from '@/components/admin-vue/ConformationContent.vue'
import SvgIcon from '@/components/general/SvgIcon.vue'

import { useToast } from 'vue-toastification';


export default {
  data() {
    return {
      loading: true,
      clickedProfile: null,
      socials: [
        { link: null, name: 'LinkedIn' },
        { link: null, name: 'Email' },
        { link: null, name: 'Phone' }
      ],
      excel: '../src/assets/icons/Excel.svg',
      accountPerPage: 35,
      members: store.state.members,
      store: store,
      db_community: db_community,
      inviteUser: false,
      inviteUserEmail: '',
      confirmDelete: false,
      openingHoursData: {},
      showOpeningHoursOverlay: false,
      sortByLastLogin: 'desc',
      sortByLastVisited: 'desc'
    }
  },
  computed: {
    filteredMembers() {
      let query = this.$route.query
      let members = this.members
      let index = parseInt((query.p ?? '0').toString())
      return members.slice(index * this.accountPerPage, (index + 1) * this.accountPerPage)
    }
  },
  components: {
    Modal,
    Profile,
    Header,
    ConformationContent,
    SvgIcon,
    OpeningHoursOverlay
  },

  created() {

  },

  methods: {
    updatePerson() {
      clickedProfile = person
      console.log('person updated')
    },

    showInvitedUsers() {
      if (this.members.length === this.store.state.members.length) {
        this.members = this.store.state.members.filter(member => member.status === 'invited');
      } else {
        this.members = this.store.state.members;
      }

    },
    toggleSortByLastLogin() {
      // Toggle sorting direction
      this.sortByLastLogin = this.sortByLastLogin === 'asc' ? 'desc' : 'asc';

      // Create a set to store unique UIDs
      const uniqueUIDs = new Set();

      // Filter and sort the members array based on last login
      this.members = this.members.filter(member => {
        // Check if the member's UID is already in the set
        if (uniqueUIDs.has(member.uid)) {
          return false; // Skip this member if UID is already in the set
        } else {
          // Add the UID to the set
          uniqueUIDs.add(member.uid);
          return true; // Include this member in the filtered array
        }
      }).sort((a, b) => {
        const dateA = a.lastLogin ? new Date(a.lastLogin.toDate()) : new Date(0);
        const dateB = b.lastLogin ? new Date(b.lastLogin.toDate()) : new Date(0);
        return this.sortByLastLogin === 'asc' ? dateA - dateB : dateB - dateA;
      });
    },

    // toggleSortByLastVisited() {
    //   // Toggle sorting direction
    //   console.log('toggleSortByLastVisited');
    //   this.sortByLastVisited = this.sortByLastVisited === 'asc' ? 'desc' : 'asc';

    //   // Create a set to store unique UIDs
    //   const uniqueUIDs = new Set();

    //   // Filter and sort the members array based on last visited
    //   this.members = this.members.filter(member => {
    //     // Check if the member has the lastVisited property and it's not null or undefined
    //     if (member.lastVisited != null) {
    //       // Check if the member's UID is already in the set
    //       if (uniqueUIDs.has(member.uid)) {
    //         return false; // Skip this member if UID is already in the set
    //       } else {
    //         // Add the UID to the set
    //         uniqueUIDs.add(member.uid);
    //         return true; // Include this member in the filtered array
    //       }
    //     } else {
    //       return false; // Skip this member if lastVisited property is missing or null
    //     }
    //   }).sort((a, b) => {
    //     // Extract lastVisited dates or default to a date in the past
    //     const dateA = a.lastVisited ? new Date(a.lastVisited.toDate()) : new Date(0);
    //     const dateB = b.lastVisited ? new Date(b.lastVisited.toDate()) : new Date(0);
    //     // Sort based on the sorting direction
    //     return this.sortByLastVisited === 'asc' ? dateA - dateB : dateB - dateA;
    //   });
    // },


    async checkOpeningHours() {
      try {
        // Attempt to fetch the opening hours
        const response = await db_community.getCommunityOpeningHours(this.$route.params.b2b_uid + '');
        console.log(response);

        // Check if the response is not null or empty
        if (response && Object.keys(response).length > 0) {
          this.openingHoursData = response;
          this.showOpeningHoursOverlay = true;
        } else {
          // Handle the case where no opening hours data is returned
          alert('No opening hours data found.');
          this.showOpeningHoursOverlay = false;
        }
      } catch (error) {
        console.error("Error fetching opening hours:", error);
        // Optionally, show an error message to the user
        alert('Failed to fetch opening hours. Please try again later.');
        this.showOpeningHoursOverlay = false;
      }
    },


    closeOpeningHoursOverlay() {
      this.showOpeningHoursOverlay = false;
    },

    async handleUpdateOpeningHours(updatedHours) {
      const toast = useToast();
      try {
        const b2b_uid = this.$route.params.b2b_uid;
        // Assuming `updateCommunityOpeningHours` is a method in your `db_community` module that updates the database
        await db_community.updateCommunityOpeningHours(b2b_uid, updatedHours);
        this.openingHoursData = updatedHours; // Update local state to reflect changes
        // Optionally, show a success message to the user
        toast('Opening hours updated successfully!', {
          timeout: 2000,
          type: 'success'
        });
      } catch (error) {
        console.error("Error updating opening hours:", error);
        // Optionally, show an error message to the user
        toast('Failed to update opening hours.', {
          timeout: 2000,
          type: 'error'
        });
      }
    },
    /**
     * changes the value of members which makes sure that when filtered members is computed
     * it contains the correct members
     * string to search for
     */
    search() {
      console.log('searching');
      const val = this.$refs.searchQuery.value.toLowerCase();
      const uniqueMembers = new Map();

      const fields = [
        'firstname',
        'prefix',
        'lastname',
        'company-name',
        'company-area',
        'company-role',
        'tag',
        'socials-Email'
      ];

      if (val.length !== 0) {
        this.store.state.members.forEach(member => {
          const isMemberIncluded = fields.some(field => {
            if (field.includes('-')) {
              const [parent, child] = field.split('-');
              return member[parent]?.[child]?.toLowerCase().includes(val);
            }
            return member[field]?.toLowerCase().includes(val);
          });

          if (isMemberIncluded) {
            // Assuming 'uid' is a unique identifier for each member
            uniqueMembers.set(member.uid, member);
          }
        });

        this.members = Array.from(uniqueMembers.values());
      } else {
        this.members = [...this.store.state.members];
      }
      console.log('Final members:', this.members.length); // Shows the final, unique count
    },


    getTableStyle(person) {
      if (person.status == 'invited')
        return { boxShadow: '-2px -2px 2px 1px #444,  0px 0px 0px 1px #444' }
      if (person.status == 'admin')
        return {
          boxShadow: `-2px -2px 2px 1px ${store.state.tenant.colors.primaryColor},  0px 0px 0px 1px ${store.state.tenant.colors.primaryColor}`
        }
    },
    closeConformation() {
      this.clickedProfile = null
      this.confirmDelete = false
    },
    conformation(person) {
      this.clickedProfile = person
      this.confirmDelete = true
      console.log('conformation opened')
    },
    async deleteUser(person) {
      const toast = useToast();
      if (person === null) {
        console.log('member is null')
        return
      }
      try {
        await db_community.deleteProfile(this.$route.params.b2b_uid + '', person.uid)
        store.state.members.forEach((element, index) => {
          if (element.uid == person.uid) store.state.members.splice(index, 1)
        })
        toast('User deleted successfully!', {
          timeout: 2000,
          type: 'success'
        })
      } catch (error) {
        console.log('member not deleted')
      }
      this.clickedProfile = null
      this.confirmDelete = false
    },
    async invite() {
      const toast = useToast();
      let res = await db_community.inviteUser(
        this.$route.params.b2b_uid + '',
        this.inviteUserEmail,
        this.store.state.tenant.tenant_name
      )
      console.log(res)
      if (res == 'OK') {
        toast('User invited successfully!', {
          timeout: 2000,
          type: 'success'
        })
      } else {
        toast('Failed to invite user.', {
          timeout: 2000,
          type: 'error'
        })
      }

      this.inviteUserEmail = ''
      // this.$forceUpdate()
    },
    async reInvite(personEmail) {
      if (personEmail == null) {
        console.log('can not reinvite because this account has no Email')
      } else {
        await db_community.inviteUser(
          this.$route.params.b2b_uid + '',
          personEmail,
          this.store.state.tenant.tenant_name
        )
      }
    },

    async resetPassword(personEmail) {
      const toast = useToast();
      if (personEmail == null) {
        console.log('can not reset password because this account has no Email')
      } else {
        const response = await auth.passwordReset(personEmail)
        if (response) {
          console.log('Password reset email sent')
          // show the toast
          toast('Password reset email sent successfully!', {
            timeout: 2000,
            type: 'success'
          })
        } else {
          console.log('Failed to send password reset email')
          // show the toast
          toast('Failed to send password reset email.', {
            timeout: 2000,
            type: 'error'
          })
        }
      }
    },

    reroute(nameOfDestination, personUid) {
      this.$router.push({ name: nameOfDestination, params: { uid: personUid } })
    },
    /**
     * returns the amount of pages based on how many user are allow per page
     * @param membersPerPage max amount of members shown on screen
     */
    pageAmount() {
      return Math.floor(store.state.members.length / this.accountPerPage) + 1
    },
    pageSelect(index) {
      this.$router.replace({ name: 'Admin', query: { p: index - 1 } })
    }
  },

  // async beforeRouteEnter(to, from, next) {

  //   next()
  // },

  async beforeCreate() {
    await auth.userState(this.$route.params.b2b_uid).then((res) => {
      if (res == null) {
        // router.replace('/login')
      } else {
        console.log('Logged into uid: ' + res.uid)
        store.state.user = res
      }
    })

    for (let i = 0; i < store.state.members.length; i++) {
      if (store.state.members[i]['uid'] == store.state.user?.uid) {
        console.log('found match')
        store.state.userProfile = store.state.members[i]
        break
      }
    }

    if (store.state.userProfile == null) {
      // TODO: do something when the user does not have a profile
      console.log('No profile?????')
    }

    this.loading = false
  },
  async beforeRouteEnter(to, from, next) {
    // store.state.tenant.colors = ["#000","#000",{icons:["#000","#000","#000"]}]

    await db_community.loadDataFromDBInStateManager(to.params.b2b_uid + '', true)
    next()
  },





}
</script>

<style lang="scss">
.admin {
  button .SvgIcons .svg-img {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    fill: v-bind('store.state.tenant.colors.textColor');
  }

  .outer-div {
    width: 100%;
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
  }

  .invited-label {
    position: absolute;
    left: 10px;
    top: auto;
    background-color: v-bind('store.state.tenant.colors.primaryColor');
    color: v-bind('store.state.tenant.colors.backgroundColor');
    padding: 5px 10px;
    border-radius: 5px;
  }

  .background {
    display: flex;
    justify-content: center;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border-radius: 10px;
    padding: 0px;
  }

  .expanded {
    width: 100%;
    overflow-y: auto;
    height: 100%;
    min-height: 100vh;
    padding: 1.25vh 1.25%;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
  }

  .align-right {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  .download-button {
    display: flex;
    align-items: center;
  }

  .page-selector {
    display: flex;
    justify-content: end;
  }

  p {
    color: v-bind('store.state.tenant.colors.textColor');
  }

  input {
    /* TODO: add dynamic colors from firestore */
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 5px;
    height: 30px;
  }

  form.input {
    width: 100%;
  }

  .inviteForm {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
    padding: 30px 5px;
  }

  main {
    overflow-x: auto;
  }

  table {
    width: 100%;
    padding: 1px;
    border-spacing: 0px 10px;
    margin: auto;
    flex-basis: 100%;
  }

  td {
    text-align: center;
    margin-top: 10px;
    color: v-bind('store.state.tenant.colors.textColor');
  }

  th {
    color: v-bind('store.state.tenant.colors.primaryColor');
  }

  tr:not(:first-child) {
    box-shadow: -2px -2px 2px 1px v-bind('store.state.tenant.colors.secondaryColor'),
      0px 0px 0px 1px v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 5px;
    height: 50px;
  }

  tr {

    @media screen,
    (max-width: 800px) {
      width: 80%;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: v-bind('store.state.tenant.colors.primaryColor');
  }

  button {
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 5px;
    margin: 0 4px;
    box-shadow: -1px -1px 2px 1px v-bind('store.state.tenant.colors.secondaryColor'),
      0px 0px 0px 1px v-bind('store.state.tenant.colors.secondaryColor');
    padding: 5px 30px;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }

  .search-bar {

    @media screen,
    (max-width: 800px) {
      width: 25%;
    }

    outline: none;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    color: v-bind('store.state.tenant.colors.textColor');
    border: 1px solid v-bind('store.state.tenant.colors.secondaryColor');
    border-radius: 5px;
    margin: 0 4px;
    box-shadow: -1px -1px 2px 1px v-bind('store.state.tenant.colors.secondaryColor'),
    0px 0px 0px 1px v-bind('store.state.tenant.colors.secondaryColor');
    // height: 30px;
    padding: 5px 10px;
    height: 40px;
  }

  .overview-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1.2fr;
    padding: 10px 20px;
    margin: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    position: relative;
  }

  .invite-button {
    padding: 5px 30px;
  }
}
</style>