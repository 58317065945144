<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60">
  <defs>
   
    <linearGradient id="linear-gradient" x1="0.79" y1="0.061" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
      <stop offset="0" :stop-color="color1"/>
      <stop offset="1" :stop-color="color2"/>
    </linearGradient>
  </defs>
  <g id="Spotify" transform="translate(-530 -288)">
    <rect id="Rectangle_21" data-name="Rectangle 21" class="cls-1" width="60" height="60" transform="translate(530 288)"/>
    <path id="Icon_awesome-spotify" data-name="Icon awesome-spotify" class="cls-2" d="M16,.563a16,16,0,1,0,16,16A16.006,16.006,0,0,0,16,.563ZM22.5,24.1a1.231,1.231,0,0,1-.69-.232c-4.026-2.426-8.71-2.529-13.335-1.581a4.166,4.166,0,0,1-.768.168A1,1,0,0,1,6.684,21.44a1.022,1.022,0,0,1,.877-1.084c5.284-1.168,10.684-1.065,15.29,1.69a1.091,1.091,0,0,1,.626,1.065.975.975,0,0,1-.981.994Zm1.735-4.232a1.593,1.593,0,0,1-.794-.271A20.908,20.908,0,0,0,8.045,17.7a2.657,2.657,0,0,1-.768.168,1.253,1.253,0,0,1-1.252-1.252,1.269,1.269,0,0,1,1-1.335,21.7,21.7,0,0,1,6.31-.877,22.584,22.584,0,0,1,11.419,2.935,1.345,1.345,0,0,1,.729,1.271,1.25,1.25,0,0,1-1.252,1.258Zm2-4.916A1.484,1.484,0,0,1,25.4,14.7c-4.594-2.742-12.806-3.4-18.123-1.916a3.064,3.064,0,0,1-.832.168,1.489,1.489,0,0,1-1.5-1.523A1.524,1.524,0,0,1,6.065,9.892a27.04,27.04,0,0,1,7.581-.981c4.71,0,9.645.981,13.252,3.084a1.515,1.515,0,0,1,.832,1.458,1.5,1.5,0,0,1-1.5,1.5Z" transform="translate(544 301.438)"/>
  </g>
</svg>
</div>
   </template>
   <script>
   export default {
     props: ["color1", "color2", "background"],
   };
   </script>
    <style>
    .cls-1 {
      fill: v-bind('background');
    }

    .cls-2 {
      fill: url(#linear-gradient);
    }
  </style>
