<template>
  <div class="outer-div-inputbar">
    <SvgIcon :IconName="IconName" />
    <slot>
      <input
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        :placeholder="placeholder"
        :required="required"
        :type="type"
        :pattern="pattern"
      />
    </slot>
  </div>
</template>

<script>
import store from '@/store'
import SvgIcon from '@/components/general/SvgIcon.vue'

export default {
  props: ['IconName', 'modelValue', 'placeholder', 'colors', 'required', 'type', 'pattern'],
  emits: ['update:modelValue'],
  data() {
    return {
      store: store
    }
  },
  components: {
    SvgIcon
  }
}
</script>

<style scoped>
.outer-div-inputbar {
  width: 100%;

  display: flex;
  align-items: center;
}




input {
  width: 100%;
  color: v-bind('store.state.tenant.colors.textColor');
  background-color: v-bind('store.state.tenant.colors.backgroundColor');
  border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
}
</style>

