import { createStore } from "vuex"

import members from "@/store/data/members"
import environment from "@/store/data/environment"

let user: any;
let userProfile: any;
let colors: any;
let tenant: any;

export default createStore({
    state: {
        members,
        environment,
        user,
        tenant,
        userProfile,
        colors,
    },
})
