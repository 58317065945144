
<template>
  <div v-if="isLoaded">
    <RouterView />
  </div>
</template>

<script>
import auth from '@/db/controller/auth'
import router from '@/router'
import store from '@/store'
import db_community from '@/db/controller/community'

export default {
  data() {
    return {
      loading: true,
      auth: auth,
      router: router,
      store: store,
      isLoaded: false
    }
  },
  mounted() {
  document.title = "A'DAM&Co. Community App";
},
  async created() {

    
    this.isLoaded=true;
  },
  methods: {}
}
</script>



<style>
</style>
