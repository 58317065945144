<template>
  <div>
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24">
  <path id="arrow-back" d="M20.679-1.62a1.443,1.443,0,0,1-.407,1.043,1.443,1.443,0,0,1-1.03.437H5.056L9.931,4.735a1.481,1.481,0,0,1-2.1,2.092l-7.4-7.4a1.48,1.48,0,0,1,0-2.092l7.4-7.4a1.48,1.48,0,0,1,2.092,0,1.48,1.48,0,0,1,0,2.092L5.056-3.1H19.241a1.443,1.443,0,0,1,1.03.437,1.443,1.443,0,0,1,.407,1.043Z" transform="translate(0 15)" />
</svg>
</div>
   </template>
   <script>
   export default {
     props: ["color1", "color2", "background"],
   };
   </script>