import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "SvgIcons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneSvg = _resolveComponent("PhoneSvg")!
  const _component_EmailSvg = _resolveComponent("EmailSvg")!
  const _component_LinkedInSvg = _resolveComponent("LinkedInSvg")!
  const _component_BriefcaseSvg = _resolveComponent("BriefcaseSvg")!
  const _component_CalendarSvg = _resolveComponent("CalendarSvg")!
  const _component_CompanySvg = _resolveComponent("CompanySvg")!
  const _component_EditSvg = _resolveComponent("EditSvg")!
  const _component_ExcelSvg = _resolveComponent("ExcelSvg")!
  const _component_PersonSvg = _resolveComponent("PersonSvg")!
  const _component_SettingsSvg = _resolveComponent("SettingsSvg")!
  const _component_TrashSvg = _resolveComponent("TrashSvg")!
  const _component_ArrowBackSvg = _resolveComponent("ArrowBackSvg")!
  const _component_BranchSvg = _resolveComponent("BranchSvg")!
  const _component_Refresh = _resolveComponent("Refresh")!
  const _component_Instagram = _resolveComponent("Instagram")!
  const _component_Skill = _resolveComponent("Skill")!
  const _component_resetPasswordSvg = _resolveComponent("resetPasswordSvg")!
  const _component_PlasholderSvg = _resolveComponent("PlasholderSvg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.IconName === 'Phone')
      ? (_openBlock(), _createBlock(_component_PhoneSvg, {
          key: 0,
          class: "svg-img",
          color1: $data.color1,
          color2: $data.color2,
          background: $data.background
        }, null, 8, ["color1", "color2", "background"]))
      : ($props.IconName === 'Email')
        ? (_openBlock(), _createBlock(_component_EmailSvg, {
            key: 1,
            class: "svg-img",
            color1: $data.color1,
            color2: $data.color2,
            background: $data.background
          }, null, 8, ["color1", "color2", "background"]))
        : ($props.IconName === 'LinkedIn')
          ? (_openBlock(), _createBlock(_component_LinkedInSvg, {
              key: 2,
              class: "svg-img",
              color1: $data.color1,
              color2: $data.color2,
              background: $data.background
            }, null, 8, ["color1", "color2", "background"]))
          : ($props.IconName === 'Briefcase')
            ? (_openBlock(), _createBlock(_component_BriefcaseSvg, {
                key: 3,
                class: "svg-img",
                color1: $data.color1,
                color2: $data.color2,
                background: $data.background
              }, null, 8, ["color1", "color2", "background"]))
            : ($props.IconName === 'Calendar')
              ? (_openBlock(), _createBlock(_component_CalendarSvg, {
                  key: 4,
                  class: "svg-img",
                  color1: $data.color1,
                  color2: $data.color2,
                  background: $data.background
                }, null, 8, ["color1", "color2", "background"]))
              : ($props.IconName === 'Company')
                ? (_openBlock(), _createBlock(_component_CompanySvg, {
                    key: 5,
                    class: "svg-img",
                    color1: $data.color1,
                    color2: $data.color2,
                    background: $data.background
                  }, null, 8, ["color1", "color2", "background"]))
                : ($props.IconName === 'Edit')
                  ? (_openBlock(), _createBlock(_component_EditSvg, {
                      key: 6,
                      class: "svg-img",
                      color1: $data.color1,
                      color2: $data.color2,
                      background: $data.background
                    }, null, 8, ["color1", "color2", "background"]))
                  : ($props.IconName === 'Excel')
                    ? (_openBlock(), _createBlock(_component_ExcelSvg, {
                        key: 7,
                        class: "svg-img",
                        color1: $data.color1,
                        color2: $data.color2,
                        background: $data.background
                      }, null, 8, ["color1", "color2", "background"]))
                    : ($props.IconName === 'Person')
                      ? (_openBlock(), _createBlock(_component_PersonSvg, {
                          key: 8,
                          class: "svg-img",
                          color1: $data.color1,
                          color2: $data.color2,
                          background: $data.background
                        }, null, 8, ["color1", "color2", "background"]))
                      : ($props.IconName === 'Settings')
                        ? (_openBlock(), _createBlock(_component_SettingsSvg, {
                            key: 9,
                            class: "svg-img",
                            color1: $data.color1,
                            color2: $data.color2,
                            background: $data.background
                          }, null, 8, ["color1", "color2", "background"]))
                        : ($props.IconName === 'Trash')
                          ? (_openBlock(), _createBlock(_component_TrashSvg, {
                              key: 10,
                              class: "svg-img",
                              color1: $data.color1,
                              color2: $data.color2,
                              background: $data.background
                            }, null, 8, ["color1", "color2", "background"]))
                          : ($props.IconName === 'ArrowBack')
                            ? (_openBlock(), _createBlock(_component_ArrowBackSvg, {
                                key: 11,
                                class: "svg-img",
                                color1: $data.color1,
                                color2: $data.color2,
                                background: $data.background
                              }, null, 8, ["color1", "color2", "background"]))
                            : ($props.IconName === 'Branch')
                              ? (_openBlock(), _createBlock(_component_BranchSvg, {
                                  key: 12,
                                  class: "svg-img",
                                  color1: $data.color1,
                                  color2: $data.color2,
                                  background: $data.background
                                }, null, 8, ["color1", "color2", "background"]))
                              : ($props.IconName === 'Refresh')
                                ? (_openBlock(), _createBlock(_component_Refresh, {
                                    key: 13,
                                    class: "svg-img",
                                    color1: $data.color1,
                                    color2: $data.color2,
                                    background: $data.background
                                  }, null, 8, ["color1", "color2", "background"]))
                                : ($props.IconName === 'Instagram')
                                  ? (_openBlock(), _createBlock(_component_Instagram, {
                                      key: 14,
                                      class: "svg-img",
                                      color1: $data.color1,
                                      color2: $data.color2,
                                      background: $data.background
                                    }, null, 8, ["color1", "color2", "background"]))
                                  : ($props.IconName === 'Skill')
                                    ? (_openBlock(), _createBlock(_component_Skill, {
                                        key: 15,
                                        class: "svg-img",
                                        color1: $data.color1,
                                        color2: $data.color2,
                                        background: $data.background
                                      }, null, 8, ["color1", "color2", "background"]))
                                    : ($props.IconName === 'resetPassword')
                                      ? (_openBlock(), _createBlock(_component_resetPasswordSvg, {
                                          key: 16,
                                          class: "svg-img",
                                          color1: $data.color1,
                                          color2: $data.color2,
                                          background: $data.background
                                        }, null, 8, ["color1", "color2", "background"]))
                                      : (_openBlock(), _createBlock(_component_PlasholderSvg, {
                                          key: 17,
                                          class: "svg-img",
                                          color1: $data.color1,
                                          color2: $data.color2,
                                          background: $data.background
                                        }, null, 8, ["color1", "color2", "background"]))
  ]))
}