<template>
    <div class="overlay">
        <div class="overlay-content">
            <h2 class="title">Change Opening Hours</h2>
            <form @submit.prevent="updateOpeningHours">
                <div class="inputWrapper" v-for="(hour, day) in openingHours" :key="day">
                    <label class="label">{{ day.charAt(0).toUpperCase() + day.slice(1) }}</label>

                    <div class="timesWrapper" v-if="!hour.closed">
                        <input type="time" v-model="hour.open">
                        <input type="time" v-model="hour.close">
                    </div>
                    <div class="checkboxWrapper">
                        <input type="checkbox" v-model="hour.closed" :true-value="true" :false-value="false">
                        <label class="checkboxLabel">Closed</label>
                    </div>

                </div>
                <div class="buttonWrapper">
                    <button class="formButton" type="submit">Save Changes</button>
                    <button class="formButton" type="button" @click="closeOverlay">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    props: ['openingHours'],
    methods: {
        updateOpeningHours() {
            // Logic to update opening hours in the database
            this.$emit('update-hours', this.openingHours)
            this.closeOverlay();
        },
        closeOverlay() {
            this.$emit('close');
        }
    }
}
</script>

<style>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    /* width: 50%; */
}

.inputWrapper {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.title {
    margin-left: 8px;
    margin-bottom: 20px;
    color: black;
}

.label {
    font-weight: bold;
    color: black;
    margin-left: 8px;
    min-width: 100px;
}

.buttonWrapper {
    display: flex;
    justify-content: left;
    margin-top: 20px;
}

.checkboxWrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    min-width: 100px;
}

.checkboxLabel {
    font-weight: bold;
    color: black;
    margin-left: 8px;
}

.timesWrapper {
    display: flex;
    align-items: center;
    justify-content: right;
    min-width: 250px;
}

.formButton {
    width: 100%;
}

input[type="checkbox"]:checked+.checkboxLabel {
    color: red;
}
</style>