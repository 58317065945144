<template>
  <div class="edit-profile">
    <!-- prevent page from loading if there is no profile to show -->

    <form @submit.prevent="saveForm()" v-if="this.profile !== undefined">
      <Header :back="'Admin'" />
      <div class="outer-border" style="margin: auto">
        <section class="normal-padding">
          <h1 class="heading-8">General Info</h1>
          <div class="center">
            <img :src="temp_image" class="profileImage" />
          </div>
          <input type="file" ref="profileImagePicker" accept="image/png, image/jpeg, image/jpg, image/gif"
            v-on:change="onImageSelected" />
          <a class="image" @click="openImagePicker()">
            {{ profile && profile.profileImage ? 'Foto veranderen' : 'Foto toevoegen' }}
          </a>

          <InputIconbar :IconName="'Person'" v-model="profile.firstname" placeholder="Firstname"
            :required="isRequired('name')" />
          <InputIconbar :IconName="'Person'" v-model="profile.prefix" placeholder="Prefix" />
          <InputIconbar :IconName="'Person'" v-model="profile.lastname" placeholder="Lastname"
            :required="isRequired('name')" />

          <InputIconbar v-model="profile.birthday" :IconName="'Calendar'" placeholder="Geboortedatum"
            :required="isRequired('birthday')" type="date" />

          <InputIconbar :IconName="'LinkedIn'" v-model="profile.socials['LinkedIn']" placeholder="LinkedIn link"
            :required="isRequired('linkedin')" />

          <InputIconbar :IconName="'Instagram'" v-model="profile.socials['Instagram']" placeholder="Instagram"
            :required="isRequired('instagram')" />

          <InputIconbar :IconName="'Email'" v-model="profile.socials['Email']" placeholder="Email"
            :required="isRequired('email')" type="email" />

          <InputIconbar :IconName="'Phone'" v-model="profile.socials['Phone']" placeholder="Telefoonnummer"
            :required="isRequired('phone')" type="tel"
            pattern="((\+[0-9]{2}\s?6|06))\s?[0-9]{3}\s?[0-9]{3}\s?[0-9]{2}" />
        </section>

        <section>
          <h1 class="heading-8">Company Info</h1>

          <InputIconbar :IconName="'Company'" v-model="profile.company.name" placeholder="Bedrijf"
            :required="isRequired('company')" />

          <InputIconbar :IconName="'Briefcase'" v-model="profile.company.role" placeholder="Rol"
            :required="isRequired('role')" />

          <InputIconbar :IconName="'Branch'" v-model="profile.company.area" placeholder="Vakgebied"
            :required="isRequired('area')">
            <select v-model="profile.company.area">
              <option value="" disabled selected>Kies je werkgebied</option>
              <option value="Food & Beverages">Food & Beverages</option>
              <option value="Tech">Tech</option>
              <option value="Marketing">Marketing</option>
              <option value="Advertising">Advertising</option>
              <option value="Music">Music</option>
              <option value="Events">Events</option>
              <option value="Design">Design</option>
              <option value="Media">Media</option>
              <option value="Fashion">Fashion</option>
              <option value="Art & Photography">Art & Photography</option>
              <option value="Film">Film</option>
              <option value="Coaching">Coaching</option>
              <option value="Journalism & Literature">Journalism & Literature</option>
              <option value="Architecture">Architecture</option>
              <option value="Journalism & Literature">Theater</option>
              <option value="Architecture">Comedy</option>
              <option value="Other">Other</option>
            </select>
          </InputIconbar>

          <InputIconbar :IconName="'Skill'" v-model="profile.company.skill" placeholder="Describe your skills!"
            :required="isRequired('website')" type="email" />

          <InputIconbar :IconName="'Email'" v-model="profile.company.email" placeholder="Website/Link"
            :required="isRequired('website')" type="email" />
        </section>

        <section>
          <h1 class="heading-8">About me</h1>
          <textarea :style="'padding: 5px 15px;font-size:16px;'" v-model="profile.bio.content"
            placeholder="What are you most proud of ? And yes, getting out of bed is also an accomplishment"
            :required="isRequired('bioContent')">
          </textarea>
        </section>

        <section>
          <!-- <input
            placeholder="Vraag..."
            v-model="profile.question.question"
            class="question"
            :required="isRequired('question')"
          /> -->

          <h1 class="heading-8">How's work?</h1>

          <table>
            <tr>
              <th class="option_text">Options</th>
            </tr>
            <tr>
              <td>
                <input type="radio" name="answer" id="answer0" value="0" :checked="profile.question.answerIndex == 0"
                  v-model="profile.question.answerIndex" :required="isRequired('question')" />
              </td>
              <td>
                <!-- <input
                  placeholder="Optie 1"
                  v-model="profile.question.answers[0]"
                  :required="isRequired('question')"
                /> -->

                <p class="option_text">Flooded with projects, don't call me</p>
              </td>
            </tr>
            <tr>
              <td>
                <input type="radio" name="answer" id="answer0" value="1" :checked="profile.question.answerIndex == 1"
                  v-model="profile.question.answerIndex" />
              </td>
              <td>
                <p class="option_text">Manageable, call me</p>
              </td>
            </tr>
            <tr>
              <td>
                <input type="radio" name="answer" id="answer0" value="2" :checked="profile.question.answerIndex == 2"
                  v-model="profile.question.answerIndex" />
              </td>
              <td>
                <p class="option_text">Zen mode activated (open for drinks)</p>
              </td>
            </tr>
            <tr>
              <td>
                <input type="radio" name="answer" id="answer0" value="2" :checked="profile.question.answerIndex == 2"
                  v-model="profile.question.answerIndex" />
              </td>
              <td>
                <p class="option_text">Open for work, I need money</p>
              </td>
            </tr>
            <tr>
              <td>
                <input type="radio" name="answer" id="answer0" value="2" :checked="profile.question.answerIndex == 2"
                  v-model="profile.question.answerIndex" />
              </td>
              <td>
                <p class="option_text">Looking for shelter, help</p>
              </td>
            </tr>
          </table>
        </section>

        <div style="display: flex; flex-direction: column">
          <div v-if="error != ''" style="color: red; background-color: white; padding: 5px 15px; border-radius: 28px">
            {{ error }}
          </div>
          <div class="buttons">
            <input class="button-style color" type="submit" value="opslaan" @click="saveForm()" />
            <a class="button-style" @click.prevent="cancelForm()">Annuleren</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Iconbar from '@/components/profile/Iconbar.vue'
import InputIconbar from '@/components/profile/InputIconbar.vue'
import Header from '@/components/general/Header.vue'
import store from '@/store'
import db_community from '@/db/controller/community'

import Person from '@/assets/Icons/Person.svg'
import Calendar from '@/assets/Icons/Calendar.svg'
import LinkedIn from '@/assets/Icons/LinkedIn.svg'
import Email from '@/assets/Icons/Email.svg'
import Skill from '@/assets/Icons/Skill.svg'
import Phone from '@/assets/Icons/Phone.svg'
import Company from '@/assets/Icons/Company.svg'
import Briefcase from '@/assets/Icons/Briefcase.svg'
import Branch from '@/assets/Icons/Branch.svg'
import auth from '@/db/controller/auth'
import SvgIcon from '@/components/general/SvgIcon.vue'

let profile
let profileImage
let b2b_uid = ''

export default {
  props: ['ownProfile'],
  components: {
    Iconbar,
    InputIconbar,
    Header,
    SvgIcon
  },
  data() {
    return {
      person: Person,
      calendar: Calendar,
      linkedin: LinkedIn,
      email: Email,
      skill: Skill,
      phone: Phone,
      company: Company,
      briefcase: Briefcase,
      branch: Branch,
      store: store,
      auth: auth,
      temp_image: '',
      error: '',
      // list of all fields: name, birthday, linkedin, email, phone, company, role, area, companyEmail, companyPhone, question, bioTitle, bioContent
      requiredFields: ['name', 'birthday', 'email', 'phone']
    }
  },
  async beforeRouteEnter(to, from, next) {
    b2b_uid = to.params.b2b_uid
    await db_community.loadDataFromDBInStateManager(to.params.b2b_uid + '', true)
    next()
  },
  async created() {

    this.profile = store.state.userProfile ?? {
      name: '',
      socials: {},
      company: {},
      question: { answers: ['', '', ''] },
      bio: {},
      isFirstTime: false
    }
    if (this.$route.params?.uid != null) {
      this.profile = store.state.members.filter((el) => el.uid == this.$route.params.uid)[0]
      this.profile.isFirstTime = false
    }

    //redirect back to admin if there is no account to show
    if (this.profile === undefined) {
      this.$router.push({ name: 'Admin' })
    }

    this.temp_image = this.profile.profileImage

    console.log('profile', this.profile)
  },
  methods: {
    /**
        Update profile of user with **uid** in community with uid **b2b_uid**

        @param b2b_uid - the uid of the community
        @param uid - the uid of the user
      */
    async updateProfileImage(b2b_uid, uid, profile) {
      if (this.profileImage) {
        profile.profileImage = await db_community.updateProfileImage(
          b2b_uid,
          uid,
          this.profileImage
        )
      }

      if (profile.status == 'invited') {
        profile.status = 'completed'
      }
      await db_community.updateProfile(b2b_uid, uid, profile)
    },
    openImagePicker() {
      this.$refs.profileImagePicker.click()
    },
    getuid() {
      return store.state.user.uid
    },
    onImageSelected(event) {
      this.profileImage = event.target.files[0]

      const reader = new FileReader()
      reader.onload = (e) => {
        this.temp_image = e.target.result
      }
      reader.readAsDataURL(event.target.files[0])
    },

    isRequired(name) {
      return this.requiredFields.includes(name)
    },

    async updateProfile() {
      await this.updateProfileImage(
        this.$route.params.b2b_uid,
        this.profile.uid ?? store.state.user.uid,
        this.profile
      )
      this.$forceUpdate()
    },

    reroute(nameOfDestination) {
      this.$router.push({ name: nameOfDestination })
    },

    cancelForm() {
      this.$router.back()
    },

    async saveForm() {
      if (
        !this.profile.firstname ||
        !this.profile.lastname ||
        !this.profile.socials.Email ||
        !this.profile.socials.Phone
      ) {
        this.error = 'Sorry you forgot to fill in your firstname, lastname, email or phonenumber!'
        return
      }

      if (
        this.profile.socials &&
        this.profile.socials.linkedin &&
        this.profile.socials.linkedin.includes('http')
      ) {
        this.error = 'Sorry you need to fill a link in the linkedin field!'
        return
      }

      if (this.profile.uid != null) {
        this.profile.uid = this.store.state.user.uid
        if (this.profile.status != 'admin') {
          this.profile.status = 'completed'
        }

        this.profile.isFirstTime = false
        // await db_community.updateProfile(b2b_uid, this.store.state.user.uid, this.profile)
        await this.updateProfileImage(b2b_uid, this.store.state.user.uid, this.profile)
      }

      window.open('/i4AH0ci2IfYZ61FcCIWe2kZA3mh2/overview', '_self')
    }
  }
}
</script>



<style lang="scss">
.edit-profile {
  .SvgIcons .svg-img {
    width: 24px;
    height: 24px;
  }

  .outer-border {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
  }

  form {
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    min-height: 100vh;
  }

  section {
    position: relative;
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    min-width: 200px;
    padding: 10px;
    margin: 10px;
    // height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 800px) {
      width: calc(50% - 20px);
    }

    @media screen and (max-width: 800px) {
      width: calc(50% - 20px);
    }

    @media screen and (max-width: 450px) {
      width: calc(100% - 20px);
    }
  }

  .center {
    display: flex;
    justify-content: center;
  }

  .profileImage {
    width: 100%;
    max-height: 200px;
    border-radius: 10px;
    object-fit: contain;
    border-radius: 28px;
  }

  h1 {
    color: v-bind('store.state.tenant.colors.textColor');
  }

  th {
    color: v-bind('store.state.tenant.colors.textColor');
  }

  table {
    width: 90%;
    margin: 0 10px;
  }

  input {
    width: 90%;
    color: #fff; //v-bind('store.state.tenant.colors.textColor');
    background-color: rgba(255, 255, 255, 0.095);
    border: 0px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    outline: none;
    /* Remove the blue outline */
  }

  input:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px v-bind('store.state.tenant.colors.primaryColor');
    outline: none;
    /* Remove the blue outline on focus */
  }

  input[type='file'] {
    display: none;
  }

  textarea {
    width: 90%;
    background-color: rgba(255, 255, 255, 0.095);
    color: v-bind('store.state.tenant.colors.textColor');
    border: 0px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    height: 110px;
    margin-top: 8px;
  }

  textarea:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px v-bind('store.state.tenant.colors.primaryColor');
    outline: none;
    /* Remove the blue outline on focus */
  }

  a {
    cursor: pointer;
  }

  a.image {
    color: v-bind('store.state.tenant.colors.textColor');
    background-color: v-bind('store.state.tenant.colors.primaryColor');
    border: none;
    border-radius: 28px;
    flex-basis: 50%;
    padding: 8px 25px;
    font-weight: 700;
    margin-bottom: 5%;
  }

  .option_text {
    color: #fff;
  }

  select {
    color: v-bind('store.state.tenant.colors.textColor');
    background-color: rgba(255, 255, 255, 0.095);
    border: 0px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    width: 100%;
    -webkit-appearance: none;
    /* WebKit browsers */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    outline: none;
    /* Remove the blue outline */
  }

  select::after {
    content: '\25BC';
    /* Unicode ▼ symbol for a down arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value to set the distance from the right edge */
    transform: translateY(-50%);
    color: #fff;
    /* White color for the arrow */
  }

  select:focus {
    border-color: #ffffff;
    box-shadow: 0 0 5px v-bind('store.state.tenant.colors.primaryColor');
    outline: none;
    /* Remove the blue outline on focus */
  }

  .question,
  .bioTitle {
    height: 40px;
    font-size: 20px;
  }

  .button-style {
    padding: 8px 30px;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
  }

  .normal-padding {
    padding: 10px;
  }

  .button-style.color {
    background-color: v-bind('store.state.tenant.colors.primaryColor') !important;
  }

  input[type='radio'] {
    appearance: none;
    margin: 0 8px;
    color: #fff; //v-bind('store.state.tenant.colors.primaryColor');
    width: 1.5em;
    height: 1.5em;
    border: 0.15em solid #fff; //v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 50%;
    display: inline-grid;
    place-content: center;
  }

  input[type='radio']::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    padding-bottom: 10px;
    box-shadow: inset 1em 1em #fff; //v-bind('store.state.tenant.colors.primaryColor');
  }

  input[type='radio']:checked::before {
    transform: scale(1);
  }

  .buttons {
    display: flex;
    justify-content: space-evenly;
    max-width: 400px;
  }

  .buttons button,
  .buttons input[type='submit'] {
    font-size: 16px;
    margin: 8px;
    color: v-bind('store.state.tenant.colors.textColor');
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 28px;
  }

  a {
    font-size: 16px;
    margin: 8px;
    color: v-bind('store.state.tenant.colors.textColor');
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 28px;
  }
}
</style>
