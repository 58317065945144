<template>
  <div class="outer">
    <div class="login-border">
      <h1>Welcome</h1>
      <form @submit.prevent="login()">
        <input placeholder="Email" type="email" autocomplete="username" @ty="error = ''" ref="email"><br>
        <input placeholder="Password" type="password" autocomplete="current-password" ref="password"><br>
        <input type="submit" value="Log in">
        <!-- <button @click="login()">
          Log in
        </button> -->
      </form>
      <p v-if="error != ''">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import db_auth from "@/db/controller/auth"
import db_community from "@/db/controller/community"

import store from "@/store"

export default {
  components: {
  },
  data() {
    return {
      error: "",
      store: store,
    }
  },
  async beforeRouteEnter(to, from, next) {
   
    store.state.tenant = await db_community.getTenantDetails(to.params.b2b_uid)
    next();
  },
  async created() {
    const user = await db_auth.userState(to.params.b2b_uid).then((user) => {
      return user
    })
  },

  methods: {
    async login() {
      var email = this.$refs.email.value
      var password = this.$refs.password.value
      const response = await db_auth.signIn(email, password)

      if (response == "") {
        this.isConfig = true
        this.$router.push({name: 'Overview'})
      } else {
        this.error = response;
      }
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
  },

  computed: {
    isConfig: {
      get() {
        return store.state.environment.isConfig
      },
      set(value) {
        store.state.environment.isConfig = value;
      },
    },
  },
}
</script>

<style scoped>
  .outer {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
  }

  .login-border {
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 10px;
    padding: 10px;
    /* max-width: 190px; */
  }

  button, input[type='submit'] {
    background-color: v-bind('store.state.tenant.colors.primaryColor');
    width: 100%;
  }

  h1 {
    color: v-bind('store.state.tenant.colors.textColor');
  }

  

  input {
    margin: 4px 0;
    color: v-bind('store.state.tenant.colors.textColor');
    background-color: v-bind('store.state.tenant.colors.backgroundColor');
    border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
    border-radius: 5px;
    height: 26px;
  }
</style>


<script setup>
</script>