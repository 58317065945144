import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";


// Config
import config_announcement from "./config-announcement";
import config_onboarding from "./config-onboarding";

import config_mozo from "./config-mozo";
import { getFunctions } from "firebase/functions";

// Initializing firebase app
const firebase = initializeApp(config_announcement);

const firebase2 = initializeApp(config_mozo, "third");
const storage = getStorage(firebase);
const db1 = getFirestore(firebase);
const auth = getAuth(firebase2);
const db2 = getFirestore(firebase2);
const functionsMozo = getFunctions(firebase2);



const onboarding_app = initializeApp(config_onboarding, 'second');
const onboarding_db = getFirestore(onboarding_app);

// const mozo_app = initializeApp(config_mozo, 'third');
// const auth = getAuth(mozo_app);

export { db1, db2, storage, auth, firebase, onboarding_db, functionsMozo };
