<template>
  <div>
    <svg
      viewBox="0 -1 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
    >
      <title>briefcase</title>
      <desc>Created with Sketch Beta.</desc>
      <defs></defs>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" sketch:type="MSPage">
        <g
          id="Icon-Set"
          sketch:type="MSLayerGroup"
          transform="translate(-204.000000, -204.000000)"
          :class="pathClass"
        >
          <path
            d="M234,217 L206,217 L206,212 C206,210.896 206.896,210 208,210 L232,210 C233.104,210 234,210.896 234,212 L234,217 L234,217 Z M218,220 C218,219.634 218.105,219.296 218.277,219 L221.723,219 C221.895,219.296 222,219.634 222,220 C222,221.104 221.104,222 220,222 C218.896,222 218,221.104 218,220 L218,220 Z M234,230 C234,231.104 233.104,232 232,232 L208,232 C206.896,232 206,231.104 206,230 L206,219 L216.142,219 C216.058,219.321 216,219.652 216,220 C216,222.209 217.791,224 220,224 C222.209,224 224,222.209 224,220 C224,219.652 223.942,219.321 223.858,219 L234,219 L234,230 L234,230 Z M216,207 C216,206.448 216.448,206 217,206 L223,206 C223.552,206 224,206.448 224,207 L224,208 L216,208 L216,207 L216,207 Z M232,208 L226,208 L226,206 C226,204.896 225.104,204 224,204 L216,204 C214.896,204 214,204.896 214,206 L214,208 L208,208 C205.791,208 204,209.791 204,212 L204,230 C204,232.209 205.791,234 208,234 L232,234 C234.209,234 236,232.209 236,230 L236,212 C236,209.791 234.209,208 232,208 L232,208 Z"
            id="briefcase"
            sketch:type="MSShapeGroup"
          ></path>
        </g>
      </g>
    </svg>
  </div>
</template>
  <script>
export default {
  props: ['color1', 'color2', 'background'],
  computed: {
    pathClass() {
      return {
        class: {
          fill: this.color1
        }
      }
    }
  }
}
</script>

<style scoped>
.class {
  fill: v-bind('color2') !important;
}
</style>