import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import JsonExcel from "vue-json-excel3"

import axios from 'axios'
import VueAxios from "vue-axios"
let app = createApp(App)
app.component("downloadExcel", JsonExcel)
app.use(VueAxios, axios);
app.use(router)


import Toast, { POSITION } from "vue-toastification";
import 'vue-toastification/dist/index.css';
app.use(Toast);

app.mount('#app')


