<template>
  <div>
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 438.5 438.5"
      style="enable-background: new 0 0 438.5 438.5"
      xml:space="preserve"
      :fill="color2"
    >
      <g>
        <path
          class="st0"
          d="M427.4,19.7c-7.8-3.2-14.5-1.9-20,4l-37.1,36.8c-20.4-19.2-43.6-34.1-69.8-44.7C274.3,5.3,247.3,0,219.3,0
		c-29.7,0-58.1,5.8-85.1,17.4c-27,11.6-50.3,27.2-70,46.8C44.6,83.8,29,107.2,17.4,134.2C5.8,161.2,0,189.6,0,219.3
		c0,29.7,5.8,58,17.4,85.1c11.6,27,27.2,50.3,46.8,70c19.6,19.6,42.9,35.2,70,46.8c27,11.6,55.4,17.4,85.1,17.4
		c32.7,0,63.9-6.9,93.4-20.7c29.5-13.8,54.6-33.3,75.4-58.4c1.5-1.9,2.2-4,2.1-6.4c-0.1-2.4-1-4.3-2.7-5.9l-39.1-39.4
		c-2.1-1.7-4.5-2.6-7.1-2.6c-3,0.4-5.2,1.5-6.6,3.4c-13.9,18.1-30.9,32.1-51.1,42c-20.2,9.9-41.6,14.8-64.2,14.8
		c-19.8,0-38.7-3.9-56.7-11.6c-18-7.7-33.6-18.1-46.7-31.3c-13.1-13.1-23.6-28.7-31.3-46.7c-7.7-18-11.6-36.9-11.6-56.7
		c0-19.8,3.9-38.7,11.6-56.7c7.7-18,18.1-33.5,31.3-46.7c13.1-13.1,28.7-23.6,46.7-31.3c18-7.7,36.9-11.6,56.7-11.6
		c38.3,0,71.5,13,99.6,39.1l-39.4,39.4c-5.9,5.7-7.2,12.3-4,19.7c3.2,7.6,8.9,11.4,16.9,11.4h127.9c4.9,0,9.2-1.8,12.8-5.4
		c3.6-3.6,5.4-7.9,5.4-12.8V36.5C438.5,28.6,434.8,22.9,427.4,19.7z"
        />
      </g>
    </svg>
  </div>
</template>
      <script>
export default {
  props: ['color1', 'color2', 'background'],
  computed: {
    pathClass() {
      return {
        class: {
          fill: this.color2
        }
      }
    }
  }
}
</script>

   <style scoped>
.class {
  fill: v-bind('color1') !important;
}
</style>
