<template>
  <div>
    <svg
      viewBox="0 -5 20 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <title>email [#1572]</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient
          gradientUnits="objectBoundingBox"
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
        >
          <stop :stop-color="color1" offset="0" />
          <stop :stop-color="color2" offset="1" />
        </linearGradient>
      </defs>
      <g id="Page-1" stroke="none" stroke-width="1">
        <g id="Dribbble-Light-Preview" transform="translate(-340.000000, -922.000000)">
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path
              d="M294,774.474 L284,765.649 L284,777 L304,777 L304,765.649 L294,774.474 Z M294.001,771.812 L284,762.981 L284,762 L304,762 L304,762.981 L294.001,771.812 Z"
              id="email-[#1572]"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['color1', 'color2', 'background']
}
</script>