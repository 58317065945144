
import store from '@/store';
import PhoneSvg from '@/assets/vueImages/Phone.vue';
import EmailSvg from '@/assets/vueImages/Email.vue';
import LinkedInSvg from '@/assets/vueImages/LinkedIn.vue';
import BriefcaseSvg from '@/assets/vueImages/Briefcase.vue';
import CalendarSvg from '@/assets/vueImages/Calendar.vue';
import CompanySvg from '@/assets/vueImages/Company.vue';
import EditSvg from '@/assets/vueImages/Edit.vue';
import ExcelSvg from '@/assets/vueImages/Excel.vue';
import PersonSvg from '@/assets/vueImages/Person.vue';
import SettingsSvg from '@/assets/vueImages/Settings.vue';
import TrashSvg from '@/assets/vueImages/Trash.vue';
import ArrowBackSvg from '@/assets/vueImages/arrow-back.vue';
import BranchSvg from '@/assets/vueImages/Branch.vue';
import PlasholderSvg from '@/assets/vueImages/Placeholder.vue';
import Refresh from "@/assets/vueImages/Refresh.vue"
import Instagram from "@/assets/vueImages/Instagram.vue"
import Skill from "@/assets/vueImages/Skill.vue"
import resetPasswordSvg from "@/assets/vueImages/resetPassword.vue"


const __default__ = {
  props: ['IconName'],
  components: {
    PhoneSvg,
    EmailSvg,
    LinkedInSvg,
    BriefcaseSvg,
    CalendarSvg,
    CompanySvg,
    EditSvg,
    ExcelSvg,
    PersonSvg,
    SettingsSvg,
    TrashSvg,
    ArrowBackSvg,
    PlasholderSvg,
    BranchSvg,
    Refresh,
    Instagram,
    Skill,
    resetPasswordSvg
  },
  data() {
    return {
      color1: store.state.tenant.colors.primaryColor,
      color2: store.state.tenant.colors.secondaryColor,
      background: store.state.tenant.colors.backgroundColor,
      store: store,
    };
  },
  // computed: {
  //   realHeight(): string {
  //     // if (this.height != null){}
  //     return this.inputHeight != null ? '24px' :  this.inputHeight
  //   },
  //   realWidth(): string {
  //     return this.width != null ? '24px' :  this.width
  //   }
  // }

};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1a59eb89": (_ctx.store.state.tenant.colors.primaryColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__