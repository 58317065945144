<template>
  <div class="content">
    <div class="center">
      <!-- TODO: Use a better image -->
      <img :src="logo" class="profileImage" />
    </div>
    <h1>{{ header }}</h1>
    <p>{{ paragraph }}</p>
    <p>{{ paragraph2 }}</p>
    <div class="combined">
      <!-- change the hard code to using a method -->
      <button class="skip" @click="closeContent()">Skip</button>
      <button class="continue" @click="redirectToEdit()">Continue</button>
      <!-- <button class="continue" @click="redirectToEdit" @mouseover.self="redirectToEdit()" @mouseover="console.log('moused over')">Continue</button> -->
    </div>
  </div>
</template>
  
  <script>
import store from '@/store'
import router from '@/router'

export default {
  methods: {
    // TODO: add method for buttons
    closeContent() {
      console.log('skip clicked')
      this.$emit('close')
    },
    redirectToEdit() {
      console.log('continue clicked')
      // this.$router.push({ name: 'Login'})
      this.$router.push({ name: 'EditProfile' })
    }
  },
  data() {
    return {
      header: 'Hello,',
      // TODO: rename
      paragraph: 'We would like to get to know you and so does our community',
      paragraph2: 'Press continue to go to the profile editing screen or skip to do it later',
      logo: store.state?.tenant?.logo ?? '../src/assets/images/mozo_logo_nopadding-01.svg',
      store: store
    }
  }
}
</script> 

  
  
  <style lang ="scss" scoped>
.content {
  border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
  padding: 10px;
}
.center {
  display: flex;
  justify-content: center;
}
.profileImage {
  width: 30%;
  height: 30%;
  object-fit: contain;
  margin: 4px;
  border-radius: 28px;
}
h1 {
  color: v-bind('store.state.tenant.colors.textColor');
  font-size: 20px;
  text-align: center;
}

p {
  color: v-bind('store.state.tenant.colors.textColor');
  margin-top: 20px;
}
.skip {
  background: v-bind('store.state.tenant.colors.backgroundColor');
  color: v-bind('store.state.tenant.colors.primaryColor');
  border: 0px;
  border-radius: 10px;
  display: block;
}
.continue {
  background: v-bind('store.state.tenant.colors.primaryColor');
  color: v-bind('store.state.tenant.colors.backgroundColor');
  border: 0px;
  border-radius: 10px;
  display: block;
}
.combined {
  border: 0px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
}
</style>