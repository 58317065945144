<template>
  <div class="backdrop" @click.self="closeModal()">
    <div :style="style" class="modal">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default{
    methods: {
      closeModal(){
        this.$emit('close')
      }
    },
    props: ['style']
  }
</script>

<style>
  .backdrop {
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    row-gap:10px;
    column-gap:10px;

    overflow-y: scroll;
  }



  .modal {
    /* overflow: auto; Display scroll bar when content overflows */
    /* max-height: 80%;
    max-width: 100%;
    display: block;
    margin: auto; */

    /* TODO: add dynamic colors from firestore */
    background-color: black;
    border-radius: 10px;

    width:100%;
  }
</style>