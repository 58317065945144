<template>
    <div class="not-found">
        <h1>404 not found</h1>
    </div>
  </template>
  
  <script>
  </script>
  
  <style scoped>
    h1 {
        color: white;
        font-size: 200px;
    }
  </style>
  
  
  <script setup>
  </script>