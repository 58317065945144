import { db1, db2, storage, onboarding_db, functionsMozo } from '@/db/firebase/index'
import { httpsCallable } from 'firebase/functions'
import {
  collection,
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  DocumentData,
  query,
  orderBy,
  where,
  QueryDocumentSnapshot
} from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import store from '@/store'
import axios from 'axios'

import auth from '@/db/controller/auth'

import methods from '@/helperMethods/general'

export default {
  /**
  Get all b2b_uid's for which there currently exists a document

  @returns array of strings representing the valid b2b_uids
*/
  async getTenantIDs() {
    const querySnapshot = await getDocs(collection(db1, 'community'))
    const docIds = querySnapshot.docs.map((doc) => doc.id)
    return docIds
  },

  /**
    Get all members for the specified community

    @param b2b_uid - the uid of the community
    @returns array of all members for the given uid
  */
  async getMembers(b2b_uid: string): Promise<DocumentData[]> {
    try {
      const membersCollectionRef = collection(doc(db1, 'community', b2b_uid), 'members')

      // Retrieve documents ordered by 'status'
      const orderedMembersQuery = query(
        collection(doc(db1, 'community', b2b_uid), 'members'),
        where('lastLogin', '!=', null),
        orderBy('lastLogin', 'desc')
      )
      const orderedMembersQuery2 = query(
        collection(doc(db1, 'community', b2b_uid), 'members'),
        orderBy('status')
      )
      const querySnapshot = await getDocs(orderedMembersQuery)
      const querySnapshot2 = await getDocs(orderedMembersQuery2)
      // Now you have the ordered documents in the querySnapshot

      return [
        ...querySnapshot.docs.map((doc) => {
          const data = doc.data()
          data.uid = doc.id
          return data
        }),
        ...querySnapshot2.docs.map((doc) => {
          const data = doc.data()
          data.uid = doc.id
          return data
        })
      ]
    } catch (error) {
      throw new Error(error as string)
    }
  },

  /**
    Get a member of the specified community

    @param b2b_uid - the uid of the community
    @param uid - the uid of the user
    @returns object representing the user, or _null_ if nonexistent
  */
  async getMember(b2b_uid: string, uid: string) {
    try {
      const documentSnapshot: any = await getDoc(doc(db1, 'community', b2b_uid, 'members', uid))

      if (documentSnapshot.exists()) {
        return documentSnapshot.data()
      } else {
        return undefined
      }
    } catch (error) {
      throw new Error(error as string)
    }
  },

  /**
    Update profile of user with **uid** in community with uid **b2b_uid**

    @param b2b_uid - the uid of the community
    @param uid - the uid of the user
  */
  async updateProfile(b2b_uid: string, uid: string, profile: object) {
    try {
      //@ts-ignore
      profile.isFirstTime = false
      await setDoc(doc(db1, 'community', b2b_uid, 'members', uid), profile, { merge: true })
    } catch (error) {
      console.log('oh no')
      throw new Error(error as string)
    }
  },

  async setLastLogin(user: any, date: Date, b2b: string) {
    try {
      // Prepare the lastLogin field for the user's profile
      const lastLoginUpdate = { lastLogin: date }

      console.log('setting last login')
      console.log("User: ", user)

      // Update the lastLogin in the user's profile
      await setDoc(doc(db1, 'community', b2b, 'members', user.uid), lastLoginUpdate, {
        merge: true
      })
    } catch (error) {
      console.log('Error setting last login:', error)
      throw new Error(error as string)
    }
  },
  /**
    Upload a new profile image for user with **uid** in community with uid **b2b_uid**

    @param b2b_uid the uid of the community
    @param uid the uid of the user
    @param image the file representing the new image
  */
  async updateProfileImage(b2b_uid: string, uid: string, image: any) {
    const storageRef = ref(storage, `${b2b_uid}/profileImages/${uid}`)

    try {
      await uploadBytes(storageRef, image)
      const downloadURL = await getDownloadURL(storageRef)
      return downloadURL
    } catch (error) {
      throw new Error(error as string)
    }
  },

  /**
    Get details of the tenant with uid

    @param uid - the uid of the tenant
    @returns object of tenant details
  */
  async getTenantDetails(uid: string) {
    try {
      const documentSnapshot = await getDoc(doc(db1, 'community', uid))
      return documentSnapshot.data()
    } catch (error) {
      throw new Error(error as string)
    }
  },

  /**
    Load all neccesary data into store

    @param uid - the uid of the community
    @param admin - whether to include all users or only completed ones
  */
  async loadDataFromDBInStateManager(uid: string, admin: boolean) {
    console.log('loading data from db in state manager')

    let members = await this.getMembers(uid)

    let completedMembers = members.filter((el) => el.status == 'completed')

    store.state.members = admin ? members : completedMembers

    let tenant = await this.getTenantDetails(uid)

    store.state.tenant = tenant

    const primaryColor = store.state.tenant.colors.backgroundColor

    const hexColorWithoutHash: string = primaryColor.replace('#', '')

    // Calculate the color with 80% opacity
    store.state.tenant.colors.liteColor = `rgba(${parseInt(
      hexColorWithoutHash.slice(0, 2),
      16
    )}, ${parseInt(hexColorWithoutHash.slice(2, 4), 16)}, ${parseInt(
      hexColorWithoutHash.slice(4, 6),
      16
    )}, 0.8)`
    store.state.environment.isConfig = store.state.tenant.admins.includes(store.state.user.uid)

    for (let i = 0; i < members.length; i++) {
      if (members[i]['uid'] == store.state.user.uid) {
        store.state.userProfile = members[i]

        break
      }
    }

    console.log('user profile:', store.state.userProfile)

    if (store.state.userProfile.status != 'completed') {
      console.log('user doesnt exist in community yet, creating new profile')
      let data = null
      try {
        const documentSnapshot: any = await getDoc(doc(db2, 'b2c', store.state.user.uid))

        if (documentSnapshot.exists()) {
          data = documentSnapshot.data()
        } else {
          return undefined
        }
      } catch (error) {
        throw new Error(error as string)
      }

      store.state.userProfile = {
        bio: {
          content: '',
          title: data.bio || ''
        },
        birthday: '',
        company: {
          name: data.company || '',
          role: '',
          area: '',
          email: ''
        },
        socials: {
          Email: data.email || '',
          Phone: data.phonenumber || ''
        },
        question: {
          answerIndex: '0',
          answers: [
            "Flooded with projects, don't call me",
            'Manageable, call me',
            'Zen mode activated (open for drinks)',
            'Open for work, I need money',
            'Looking for shelter, help'
          ],
          question: "How's work?"
        },

        firstname: data.firstname || '',
        lastname: data.lastname || '',
        prefix: '',
        profileImage: data.image || '',
        status: 'completed',
        uid: store.state.user.uid
      }
    }
  },

  /**
    Initializes a new tenant in the community document in firestore

    @param b2b_uid - the uid to use for the new tenant
    @param fields - fields to add to the newly created document, 
    { 
      logo: 'https://logo.com/logo',
      admins: ['F2U6nrkvJMbaGi2JJC6DM8Wa2vV2],
      tenant_name: adamandco,
      colors: [
        'backgroundColor': #000000,
        'primaryColor: #ff0000,
        'secondaryColor':#ffffff,
        'textColor':#ffffff
      ]
    }
 

    @returns url to new tenant's community overview page
  */
  async addNewTenant(b2b_uid: string, fields: any) {
    // fails test when this is added and i don't think we need it
    // if(!('colors' in Object.keys(fields))){
    //   fields.set('colors', {
    //     backgroundColor: '#000000',
    //     primaryColor: '#ffffff',
    //     secondaryColor: '#ffffff',
    //     textColor: '#ffffff'
    //   })
    // }

    try {
      const communityDocRef = doc(db1, 'community', b2b_uid)
      await setDoc(communityDocRef, fields ?? {})

      const membersCollectionRef = collection(communityDocRef, 'members')
      await addDoc(membersCollectionRef, {})
    } catch (error) {
      throw new Error(error as string)
    }

    return `${b2b_uid}/overview`
  },

  /**
    Delete member from the specified community

    @param b2b_uid - the uid of the community
    @param uid - the uid of the member to delete
  */
  async deleteProfile(b2b_uid: string, uid: string) {
    // TODO: call cloud function to delete user from auth
    try {
      let userCreatedAccount = true
      // Check the user status before deleting
      const memberData = await this.getMember(b2b_uid, uid)
      if (!memberData) {
        console.log(`Member with ID ${uid} does not exist.`)
        return
      } else if (memberData.status === 'invited') {
        console.log(`Member with ID ${uid} is a invited user.`)
        userCreatedAccount = false
      }

      if (userCreatedAccount) {
        // Create a callable function for 'deleteUserFromAuth'
        const deleteUserFromAuth = httpsCallable(functionsMozo, 'deleteUserFromAuth')
        // Ensure to pass the actual `uid` variable, not the string "uid"
        const response = await deleteUserFromAuth({ uid }) // Here, `uid` is used directly
        console.log(response)
      }
      // Reference to the member document in Firestore
      const memberDocRef = doc(db1, 'community', b2b_uid, 'members', uid)
      // Delete the member document
      await deleteDoc(memberDocRef)
      console.log('User deleted successfully')
    } catch (error) {
      // Ensure that you are capturing and handling the error properly
      console.error('Failed to delete user:', error)
      throw new Error(error as string)
    }
  },

  async addUserInOnboarding(b2b_uid: string, b2b: string, email: string) {
    const doc_id = await methods.randomGenerator(20)

    try {
      await setDoc(doc(onboarding_db, 'b2b', b2b, 'emailadresses', doc_id), {
        activationcode: doc_id,
        changeCode: true,
        email: email,
        uid: b2b_uid
      })

      await setDoc(doc(onboarding_db, 'b2b', b2b, 'b2c', doc_id), {
        active: false,
        created_at: null
      })

      return doc_id
    } catch (error) {
      console.error('Error setting documents:', error)
      return false
    }
  },

  async getAllDocumentsAndSetUserProfile() {
    try {
      // Retrieve documents from Firestore collection
      const orderedMembersQuery = query(collection(db2, 'b2c'))
      const querySnapshot = await getDocs(orderedMembersQuery)

      const user_temp = {
        bio: {
          content: '',
          title: ''
        },
        birthday: '',
        company: {
          name: '',
          role: '',
          area: '',
          email: ''
        },
        socials: {
          Email: '',
          Phone: ''
        },
        question: {
          answerIndex: '0',
          answers: [
            "Flooded with projects, don't call me",
            'Manageable, call me',
            'Zen mode activated (open for drinks)',
            'Open for work, I need money',
            'Looking for shelter, help'
          ],
          question: "How's work?"
        },
        firstname: '',
        lastname: '',
        prefix: '',
        profileImage: '',
        status: 'completed',
        uid: ''
      }

      for (const docSnapshot of querySnapshot.docs) {
        let data = docSnapshot.data()
        data.id = docSnapshot.id

        const memberData = await this.getMember('i4AH0ci2IfYZ61FcCIWe2kZA3mh2', data.id)

        if (memberData) {
          console.log(`Member with ID ${data.id} already exists.`)
          // You can handle this case accordingly if needed
        } else {
          // Create a new userProfile object for each document
          const userProfile = { ...user_temp }

          userProfile.bio.title = data.bio || user_temp.bio.title
          userProfile.company.name = data.company || user_temp.company.name
          userProfile.company.role = data.role || user_temp.company.role
          userProfile.company.area = data.area || user_temp.company.area
          userProfile.company.email = data.email || user_temp.company.email
          userProfile.socials.Email = data.email || user_temp.socials.Email
          userProfile.socials.Phone = data.phonenumber || user_temp.socials.Phone
          userProfile.firstname = data.firstname || user_temp.firstname
          userProfile.lastname = data.lastname || user_temp.lastname
          userProfile.profileImage = data.image || user_temp.profileImage
          userProfile.uid = data.id || user_temp.uid

          // Call updateProfile function to update the user's profile for each document
          await this.updateProfile('i4AH0ci2IfYZ61FcCIWe2kZA3mh2', userProfile.uid, userProfile)
          console.log(`User profile for document ${docSnapshot.id} updated successfully.`)
        }
      }

      console.log('All user profiles updated successfully.')
    } catch (error) {
      console.error('Error updating user profiles:', error)
    }
  },

  /**
  Invite user to the specified community

  @param b2b_uid - the uid of the community
  @param email - the email of the user to invite
*/
  async inviteUser(b2b_uid: string, email: string, b2b_name: string) {
    let error

    email = email.toLowerCase().replace(/\s/g, '')
    let doc_uid
    try {
      const doesExist = await auth.emailExists(email)
      if (doesExist) {
        error = 'Sorry! Emailadres already exists!'
        return false
      }

      const membersCollectionRef = collection(doc(db1, 'community', b2b_uid), 'members')
      const response = await addDoc(membersCollectionRef, {
        socials: { Email: email },
        company: {},
        question: {
          answers: []
        },
        bio: {},
        status: 'invited'
      })
      doc_uid = await this.addUserInOnboarding(b2b_uid, b2b_name, email)
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error:', error)
      throw error // Optionally re-throw the error to handle it elsewhere if needed
    }

    try {
      const requestData = {
        auth_token: '12345',
        email: email,
        title: "Activate the new A'DAM&Co. app",
        message: `
        Dear member,<br>
        Follow these 2 easy steps to register to our new A'DAM&Co. (Web)App. <br>
        1. Click on your personal registration link to register for our new A'DAM&Co. App: 
        <a href='https://register.themozo.app/b2c?code=${doc_uid}'>personal link</a><br>
        2. Follow the short tutorial to save the web app on your home screen: 
        <a href='https://youtube.com/shorts/JQS6o7nAHnM?feature=share'>instructions</a><br><br>
        If you have any questions, email us at adam@adamandco.nl<br><br>
        Love,<br>
        A'DAM&Co. & Team Mozo
      `
      }

      // Define the request configuration
      const requestConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      }

      // Make the POST request using Axios
      const response = await axios.post(
        'https://api.themozo.app/general/email/sendEmail',
        requestData,
        requestConfig
      )

      // Return the response or perform any other actions based on the response
      return response.data
    } catch (error) {
      // Handle any errors that occur during the request
      console.error('Error:', error)
      throw error // Optionally re-throw the error to handle it elsewhere if needed
    }
  },

  async getCommunityOpeningHours(b2b_uid: string) {
    try {
      const dayOrder = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ]
      const openingHoursCollectionRef = collection(db1, 'community', b2b_uid, 'openingHours')
      const snapshot = await getDocs(openingHoursCollectionRef)

      // Define the openingHours with an index signature right here
      let openingHours = {} as Record<string, { open: string; close: string; closed?: boolean }>
      snapshot.forEach((doc) => {
        const dayData = doc.data() as { open: string; close: string; closed?: boolean }
        openingHours[doc.id] = dayData
      })

      // Sort the keys based on the predefined dayOrder and reconstruct the object
      let sortedOpeningHours = {} as Record<
        string,
        { open: string; close: string; closed?: boolean }
      >
      dayOrder.forEach((day) => {
        if (openingHours[day]) {
          sortedOpeningHours[day] = openingHours[day]
        }
      })

      return sortedOpeningHours
    } catch (error) {
      throw new Error(error as string)
    }
  },

  async updateCommunityOpeningHours(
    b2b_uid: string,
    openingHours: Record<string, { open: string; close: string; closed: boolean }>
  ): Promise<void> {
    try {
      const promises = Object.entries(openingHours).map(([day, hours]) => {
        const dayRef = doc(db1, 'community', b2b_uid, 'openingHours', day)
        return setDoc(dayRef, hours, { merge: true }) // Use merge to update or add fields without overwriting existing data
      })

      await Promise.all(promises)
      console.log('Opening hours updated successfully')
    } catch (error) {
      console.error('Error updating opening hours:', error)
      throw error // Rethrowing the error to handle it in the calling context
    }
  }
}
