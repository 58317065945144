<template>
  <div class="header-column-tm margin-top">
    <div>
      <template v-if="back">
        <div @click="backClicked()" class="iconwrapper">
          <SvgIcon :IconName="'ArrowBack'"/>
          <!-- <img src="@/assets/images/left-arrow.svg" loading="lazy" class="back-button settings-image" style="height:60px;width:60px;cursor:pointer" /> -->
        </div>
      </template>
       <template v-if="backToHome">
        <div @click="showAllApps()" class="iconwrapper">
          <SvgIcon :IconName="'ArrowBack'"/>
          <!-- <img src="@/assets/images/left-arrow.svg" loading="lazy" class="back-button settings-image" style="height:60px;width:60px;cursor:pointer" /> -->
        </div>
      </template>
    </div>
    <div class="logo-header">
      <img :src="logo" loading="lazy" class="logo" />
    </div>
    <div class="header-div right">
      <div @click="reroute('Admin')" class="iconwrapper" v-if="store.state.environment.isConfig">
        <SvgIcon :IconName="'Settings'"/>
        <!-- <img :src="settings" loading="lazy" class="settings-image icon" style="height:60px;width:60px;cursor:pointer;" /> -->
      </div>
      <div v-else class="iconwrapper"></div>
      <div @click="reroute('EditProfile')" class="iconwrapper">
        <SvgIcon :IconName="'Person'"/>
        <!-- <img :src="person" loading="lazy" class="settings-image" style="height:60px;width:60px;cursor:pointer;"/> -->
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import SvgIcon from '@/components/general/SvgIcon.vue';

// import Settings from "@/assets/icons/Settings.svg"
// import Person from "@/assets/icons/Person.svg"

export default {
  props: ["back", "forceNew","backToHome"],
  components: {
      SvgIcon,
    },
  data: () => {
    return {
      logo: store.state?.tenant?.logo ?? "../src/assets/images/mozo_logo_nopadding-01.svg",
      back_checked: "",
      store: store,

      settings: "../../src/assets/icons/Settings.svg",
      person: "../../src/assets/icons/Person.svg"
    }
  },
  methods: {
    isClicked() {
      this.$router.push(this.back)
    },
    showAllApps() {
      window.location.href = this.backlink
    },
    backClicked() {
      this.$router.push({name:this.back})
    },
    reroute(nameOfDestination) {
      this.$router.push({name: nameOfDestination})
    },
  },
  computed: {
    backlink: function () {
      return store.state.b2b.backlink
    },
  },
}
</script>

<style>
.header-column-tm .iconwrapper .SvgIcons .svg-img {
  height: 40px;
  width: 40px;
}

.iconwrapper {
  cursor:pointer;
}


.header-column-tm.margin-top {
  margin-top: 0%;
  margin-bottom: 0%;
  width: 100%;
  height: auto;
  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.header-column-tm .header-div {
  display: flex;
}

.header-column-tm .iconwrapper {
  height: 40px;
  width: 40px;
}


.header-column-tm.margin-top {
  padding: 2% 5%;
}


.header-column-tm .logo-header {
  display:flex;
  justify-content: center;
}

.header-column-tm .logo {
  border-radius: 100%;
  border-style: solid;
  border-color: #fff; /*//v-bind('store.state.tenant.colors.primaryColor');*/
  border-width: 2px;

  width: 80px;
}

.header-column-tm .settings-div {
  width:auto;
  height:auto;
}



.header-column-tm .settings-image {
  filter: drop-shadow(0px 10000px 0 v-bind('store.state.tenant.colors.primaryColor'));
  transform: translateY(-10000px);
}

.header-div.right{
  display:flex;
  justify-content: flex-end;
}
</style>
