<template>
  <div class="profile">
    <div class="outer-div">
      <Header2 :back="'Overview'" @closePopup="closePopup" />
      <div class="body">
        <section class="normal-padding">
          <div class="center" style="margin-bottom: 5%">
            <img :src="profile.profileImage" class="profileImage" />
          </div>

          <div class="left-align">
            <Iconbar
              v-if="profile.name != ''"
              :contents="profile.firstname + ' ' + profile.prefix + ' ' + profile.lastname"
              :IconName="'Person'"
            />
            <Iconbar
              v-if="profile.birthday != ''"
              :contents="profile.birthday"
              :IconName="'Calendar'"
            />
            <Iconbar
              v-if="profile.socials['LinkedIn'] != ''"
              :contents="profile.socials['LinkedIn']"
              :IconName="'LinkedIn'"
            />
            <Iconbar
              v-if="profile.socials['Email'] != ''"
              :contents="profile.socials['Email']"
              :IconName="'Email'"
            />
            <Iconbar
              v-if="profile.socials['Phone'] != ''"
              :contents="profile.socials['Phone']"
              :IconName="'Phone'"
            />
          </div>
        </section>

        <section
          class="normal-padding"
          v-if="profile.company != null && Object.keys(profile.company).length != 0"
        >
          <div class="left-align">
            <h1 class="heading-5">Bedrijfs informatie</h1>
            <Iconbar
              v-if="profile.company.name != ''"
              :contents="profile.company.name"
              :IconName="'Company'"
            />
            <Iconbar
              v-if="profile.company.role != ''"
              :contents="profile.company.role"
              :IconName="'Briefcase'"
            />

            <Iconbar
              v-if="profile.company.area != ''"
              :contents="profile.company.area"
              :IconName="'Branch'"
            />
            <Iconbar
              v-if="profile.company.email != ''"
              :contents="profile.company.email"
              :IconName="'Email'"
            />
          </div>
        </section>

        <section
          class="normal-padding"
          v-if="profile.question != null && profile.question.question != null"
        >
          <div class="left-align">
            <h1 class="heading-5">{{ profile.question.question }}</h1>
            <div class="indent-padding">
              <h3>{{ profile.question.question }}</h3>
              <div style="display: flex; row-gap: 10px; margin-top: 10px">
                <label
                  v-if="profile.question.answerIndex == 0"
                  for="option0"
                  :class="{ correct: profile.question.answerIndex == 0 }"
                >
                  <input
                    v-model="selectedAnswer"
                    type="radio"
                    value="0"
                    name="question"
                    id="option0"
                    :class="{ correct: profile.question.answerIndex == 0 }"
                  />
                  {{ profile.question.answers[0] }}
                </label>
                <label
                  v-if="profile.question.answerIndex == 1"
                  for="option1"
                  :class="{ correct: profile.question.answerIndex == 1 }"
                >
                  <input
                    v-model="selectedAnswer"
                    type="radio"
                    value="1"
                    name="question"
                    id="option1"
                    :class="{ correct: profile.question.answerIndex == 1 }"
                  />
                  {{ profile.question.answers[1] }}
                </label>
                <label
                  v-if="profile.question.answerIndex == 2"
                  for="option2"
                  :class="{ correct: profile.question.answerIndex == 2 }"
                >
                  <input
                    v-model="selectedAnswer"
                    type="radio"
                    value="2"
                    name="question"
                    id="option2"
                    :class="{ correct: profile.question.answerIndex == 2 }"
                  />
                  {{ profile.question.answers[2] }}
                </label>
              </div>
            </div>
          </div>
        </section>

        <section class="normal-padding" v-if="profile.bio?.title != null">
          <h1 class="heading-5">{{ profile.bio.content }}</h1>
          <div class="indent-padding">
            <!-- <p>{{ profile.bio.content }}</p> -->
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Iconbar from '@/components/profile/Iconbar.vue'
import SvgIcon from '@/components/general/SvgIcon.vue'
import store from '@/store'
import Header2 from '@/components/general/Header2.vue'

export default {
  props: ['profile'],
  components: {
    Iconbar,
    SvgIcon,
    Header2
  },
  data() {
    return {
      store: store,
      selectedAnswer: null
    }
  },
  created() {
    console.log(this.profile)
  },
  methods: {
    closePopup() {
      this.$emit('close', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.heading-5 {
  font-weight: 800;
  padding: 5px;
  text-transform: capitalize;
  background-color: v-bind('store.state.tenant.colors.backgroundColor');
}

.indent-padding {
  padding-left: 5px;
}

.body {
  padding: 0% 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.profile .SvgIcons .svg-img {
  width: 24px;
  height: 24px;
}

.profile section .SvgIcons .svg-img {
  left: 0;
}
.profile .outer-div {
  // overflow: auto; /* Display scroll bar when content overflows */
  max-height: 100%;
}

.profile section {
  border: 1px solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 10px;
  width: calc(100%);
  // margin: 10px;
  padding-left: 10px;
  word-wrap: break-word;
}

.profile .center {
  display: flex;
  justify-content: center;
}

.profile .profileImage {
  object-fit: contain;
  border-radius: 28px;
  width: 300px;
  height: 200px;
}

.profile input[type='radio'] {
  appearance: none;
  margin: 0 8px;
  color: v-bind('store.state.tenant.colors.primaryColor');
  width: 1.5em;
  height: 1.5em;
  border: 0.15em solid v-bind('store.state.tenant.colors.primaryColor');
  border-radius: 50%;
  display: inline-grid;
  place-content: center;
}

.profile input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  padding-bottom: 10px;
  box-shadow: inset 1em 1em v-bind('store.state.tenant.colors.primaryColor');
}

.profile input[type='radio']:checked::before {
  transform: scale(1);
}
.profile input[type='radio'].correct:checked {
  background-color: v-bind('store.state.tenant.colors.primaryColor');
}
.profile input[type='radio']:checked::before {
  background-color: v-bind('store.state.tenant.colors.primaryColor');
}

.profile label.correct {
  color: v-bind(
    'selectedAnswer == profile.question?.answerIndex ? store.state.tenant.colors.primaryColor : store.state.tenant.colors.textColor'
  );
}

.profile label {
  color: v-bind('store.state.tenant.colors.textColor');
}

.profile h1 {
  color: v-bind('store.state.tenant.colors.textColor');
}

.profile h3 {
  color: v-bind('store.state.tenant.colors.textColor');
}

.profile p {
  color: v-bind('store.state.tenant.colors.textColor');
}

.normal-padding {
  padding: 1% 2%;
  margin-bottom: 20px;

  padding-bottom: 20px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-align {
  width: 100%;
  padding: 0 5%;
}
</style>

