<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1." xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve">

            <g>
                <path
                    d="M371.139 346.76v-94.77c0-16.33-9.52-30.469-23.289-37.15V202c0-50.729-41.203-91.999-91.851-91.999s-91.851 41.27-91.851 91.999v12.84c-13.77 6.68-23.289 20.82-23.289 37.15v94.77c0 22.74 18.47 41.24 41.16 41.24h147.96c22.69 0 41.16-18.5 41.16-41.24zm-115.14-206.759c34.135 0 61.899 27.81 61.899 61.999v8.749H194.099V202c0-34.189 27.765-61.999 61.9-61.999zm-14.98 169.749V289c0-8.28 6.71-15 14.98-15s14.98 6.72 14.98 15v20.75c0 8.29-6.71 15-14.98 15s-14.98-6.71-14.98-15z"
                    fill="#ffffff" opacity="1" data-original="#000000"></path>
                <path
                    d="M221.165 458.431c-47.091-8.067-90.188-32.696-121.352-69.352C68.303 352.016 50.95 304.754 50.95 256s17.353-96.016 48.863-133.078c23.75-27.935 54.433-48.874 88.529-60.832l-5.311 9.443c-4.06 7.218-1.51 16.365 5.696 20.431 7.076 4.029 16.316 1.577 20.398-5.706l22.509-40.017c4.05-6.806 1.589-16.324-5.259-20.3L187.019 2.159c-7.083-4.28-16.287-1.999-20.559 5.095s-1.995 16.313 5.087 20.592l8.751 5.288C140.48 46.727 104.643 70.97 77.01 103.472 40.891 145.956 20.999 200.125 20.999 256S40.891 366.044 77.01 408.528c35.707 41.999 85.109 70.223 139.106 79.472 7.967 1.424 15.929-4.046 17.286-12.256 1.394-8.165-4.084-15.917-12.237-17.313zM434.988 103.472C399.281 61.473 349.879 33.249 295.882 24c-8.155-1.395-15.892 4.091-17.286 12.256-1.394 8.166 4.084 15.917 12.236 17.314 47.09 8.067 90.187 32.696 121.352 69.352 31.51 37.062 48.864 84.324 48.864 133.078s-17.353 96.016-48.864 133.078c-23.75 27.935-54.433 48.874-88.529 60.832l5.312-9.443c4.06-7.218 1.51-16.365-5.696-20.431-7.206-4.064-16.338-1.512-20.398 5.706l-22.509 40.017c-4.05 6.812-1.592 16.322 5.259 20.3l39.357 23.782c6.968 4.243 16.261 2.059 20.559-5.095 4.272-7.094 1.995-16.313-5.087-20.592l-8.751-5.288c39.818-13.593 75.656-37.836 103.289-70.338 36.119-42.484 56.011-96.653 56.011-152.528s-19.894-110.044-56.013-152.528z"
                    fill="#ffffff" opacity="1" data-original="#000000"></path>
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    props: ['color1', 'color2', 'background'],
    computed: {
        pathClass() {
            return {
                class: {
                    fill: this.color2
                }
            }
        }
    }
}
</script>

<style scoped>
.class {
    fill: v-bind('color1') !important;
}
</style>